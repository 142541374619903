import { Each } from "../common/Each";
import FeedCard from "../components/cards/FeedCard";
import FeedInputCard from "../components/cards/FeedInputCard";
import styles from "./ModuleFeed.module.css";

const ModuleFeed = ({ feed = [], moduleId, prefetchedLessons, onChange = () => { } }) => {

  return (
    <div className={styles.container}>
      <FeedInputCard moduleId={moduleId} prefetchedLessons={prefetchedLessons} onChange={onChange} />
      <Each of={feed} render={(activity, index) => {
        return (
          <FeedCard activity={activity} onChange={onChange} />
        )
      }} />
    </div>
  );
};

export default ModuleFeed;

import { useCallback, useEffect, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import api from "../api"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as EmptyIllustration } from "../assets/images/illustrations/il-empty.svg"
import { Each } from "../common/Each"
import Button from "../components/Button"
import Card from "../components/cards/Card"
import ExerciseCard from "../components/cards/ExerciseCard"
import ExerciseDialog from "../components/dialogs/ExerciseDialog"
import EmptyContent from "../components/EmptyContent"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Skeleton from "../components/Skeleton"
import TextInput from "../components/TextInput"
import { DialogStatus } from "../enums"
import typo from "../typography.module.css"
import styles from "./Exercises.module.css"

const Exercises = ({ }) => {

  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const [exercises, setExercises] = useState([])
  const [filteredExercises, setFilteredExercises] = useState([])

  const [exercisesPerModule, setExercisesPerModule] = useState([])
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [dialogStatus, setDialogStatus] = useState(DialogStatus.Default)
  const [currentExercise, setCurrentExercise] = useState(null)
  const [dialogTimeout, setDialogTimeout] = useState(null)

  useEffect(() => {

    getExercises();
  }, []);

  useEffect(() => {
    const exercisesPerModule = {}
    for (const exercise of filteredExercises) {
      const { module } = exercise;
      if (module.id in exercisesPerModule) {
        exercisesPerModule[module.id].exercises.push(exercise)
      } else {
        exercisesPerModule[module.id] = {
          moduleId: module.id,
          moduleName: module.name,
          exercises: [exercise]
        }
      }
    }
    console.debug(exercisesPerModule)
    const epm = Object.values(exercisesPerModule)
    setExercisesPerModule(epm)
  }, [filteredExercises])

  useEffect(() => {
    if (!open) {
      setCurrentExercise(null)
    }
  }, [open])

  const getExercises = useCallback(async () => {
    setLoading(true);
    try {
      const exercises = await api.get("/teacher/exercises");
      console.debug(exercises)
      setExercises(exercises);
      setFilteredExercises(exercises);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, [])

  const onSave = useCallback(() => {
    getExercises()
    setDialogTimeout(setTimeout(() => setOpen(false), 4000))
  }, [])

  const onSearch = useCallback((text) => {
    if (exercises && exercises.length > 0) {
      if (text) {
        const filter = exercises.filter((s) => {
          const target = `${s.name}`.toLowerCase().replaceAll(" ", "");
          const query = text ? text.toLowerCase().replaceAll(" ", "") : text
          return target.includes(query);
        });
        setFilteredExercises(filter);
      } else {
        setFilteredExercises(exercises);
      }
    }
  }, [exercises])

  const onDelete = useCallback(() => {
    getExercises()
  }, [])

  const onEdit = useCallback((exercise) => {
    setCurrentExercise(exercise)
    setOpen(true)
  }, [])

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>{t("exercises.title")}</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={typo.title}>{t("exercises.title")}</div>
                <TextInput
                  onKeyUp={onSearch}
                  type="search"
                  placeholder={"Ricerca"}
                  style={{
                    backgroundColor: "var(--background-secondary-color)",
                    maxWidth: "256px",
                  }} />

                <div className={styles.add}>
                  <Button
                    style={{
                      padding: "1rem 2rem",
                    }}
                    onClick={() => setOpen(true)}
                  >
                    {t("exercises.add").toUpperCase()}
                    <AddIcon />
                  </Button>
                </div>
              </div>
              <div className={styles.modules}>
                {
                  loading === true &&
                  <Each
                    of={[1, 2]}
                    render={() => (
                      <Card>
                        <div className={styles.module}>
                          <div className={typo.subheadline}>
                            <Skeleton type="rect" width="256px" height={"22px"} borderRadius={"8px"} />
                          </div>
                          <div className={styles.exercises}>
                            <Each
                              of={[1, 2, 3]}
                              render={() => (
                                <div className={styles.exercise}>
                                  <ExerciseCard loading />
                                </div>)}
                            />
                          </div>
                        </div>
                      </Card>
                    )}
                  />
                }

                {
                  loading === false && exercisesPerModule.length > 0 &&
                  <Each
                    of={exercisesPerModule}
                    render={(moduleExercises, moduleIndex) => (
                      <Card>
                        <div className={styles.module}>
                          <div className={typo.subheadline}>{moduleExercises.moduleName}</div>
                          <div className={styles.exercises}>
                            <Each
                              of={moduleExercises.exercises.sort((a, b) => {
                                if (!a.deleted_at && b.deleted_at) {
                                  return -1
                                }
                                return 1
                              })}
                              render={(exercise) => (
                                <div className={styles.exercise}>
                                  <ExerciseCard exercise={exercise} onDelete={onDelete} onEdit={() => onEdit(exercise)} />
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </Card>
                    )}
                  />
                }

                {
                  loading === false && exercisesPerModule.length === 0 &&
                  <EmptyContent
                    Illustration={EmptyIllustration}
                    title={"Nessun esercizio ancora creato"}
                    message={
                      <div style={{ display: "flex", alignItems: "center", }}>
                        <Button
                          appearance="text"
                          onClick={() => setOpen(true)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: ".2rem"
                          }}
                        >
                          <AddIcon />
                          Aggiungi
                        </Button>
                        un nuovo esercizio per i tuoi studenti
                      </div>
                    }
                  />
                }
              </div>
            </div>
            <ExerciseDialog
              open={open}
              onClose={() => {
                if (dialogTimeout) {
                  clearTimeout(dialogTimeout)
                  setDialogTimeout(null)
                }
                setOpen(false)
              }}
              exercise={currentExercise}
              status={dialogStatus}
              onSave={onSave}
            />
          </div>
        </div>
      </div>

    </HeaderFooterLayout>
  )
}


export default Exercises

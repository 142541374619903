import { useCallback } from "react";
import { ReactComponent as DownloadIcon } from "../assets/images/icons/ic-download.svg";
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg";
import { formatFileSize } from "../utils";
import styles from "./MaterialButton.module.css";

const MaterialButton = ({ material, onClick = () => { }, downloadable = false }) => {

  const onDownload = useCallback(() => {
    const link = document.createElement("a");
    link.href = material.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [material]);

  return (
    <div className={styles.material}>
      <div className={styles.info}>
        <div className={styles.name}>{material.name}</div>
        <div className={styles.size}>{formatFileSize(material.size)}</div>
      </div>
      {
        downloadable &&
        <button className={styles.download} onClick={onDownload}>
          <DownloadIcon />
        </button>
      }
      {
        !downloadable &&
        <button className={styles.close} onClick={onClick}>
          <CloseIcon />
        </button>
      }
    </div>
  );
};

export default MaterialButton;

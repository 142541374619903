
import { forwardRef } from "react"
import styles from "./SortableContainer.module.css"
import { Each } from "../common/Each"
import { ReactComponent as DragIcon } from "../assets/images/icons/ic-drag.svg"

const SortableContainer = forwardRef((props, ref) => {
  return (
    <div ref={ref} className={styles.draggable}>
      <Each
        of={props.children}
        render={(child) => (
          <div className={styles.draggableItem}>
            <div className={styles.drag}><DragIcon /></div>
            {child}
          </div>
        )}
      />
    </div>
  )
})
export default SortableContainer

import { useTranslation } from "react-i18next"
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/ic-calendar.svg"
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-edit.svg"
import { Each } from "../../common/Each"
import { formatDateV2, formatTimeV2 } from "../../utils"
import Button from "../Button"
import Skeleton from "../Skeleton"
import Tag from "../Tag"
import styles from "./ModuleLessonCard.module.css"

const ModuleLessonCard = ({ lesson, onEdit = () => { }, loading = false }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.container}>
            {
                loading === true &&
                <>
                    <div className={styles.left} >
                        <Skeleton type="rect" width="144px" height="18px" borderRadius="4px" />
                        <Skeleton type="rect" width="96px" height="16px" borderRadius="4px" />
                        <Skeleton type="rect" width="100%" height="16px" borderRadius="4px" />

                        <div className={styles.tags}>
                            <Each
                                of={[130, 140, 86, 44, 112]}
                                render={(tagWidth) => (
                                    <Skeleton type="rect" width={`${tagWidth}px`} height="24px" borderRadius="8px" />
                                )}
                            />
                        </div>
                        <Skeleton type="rect" width="200px" height="40px" borderRadius="200px" />

                    </div>
                    <div className={styles.right}>
                        <Skeleton type="rect" width="230px" height="130px" borderRadius="12px" />
                    </div>
                </>
            }

            {
                !loading &&
                <>
                    <div className={styles.left}>
                        <div className={styles.name}>{lesson.name}</div>
                        <div className={styles.time}><CalendarIcon /> {formatTimeV2(lesson.starts_at)}, {formatDateV2(lesson.starts_at)}</div>
                        {lesson.description &&
                            <div className={styles.description}>{lesson.description}</div>
                        }
                        <div className={styles.tags}>
                            <Each
                                of={lesson.tags ?? []}
                                render={
                                    (tag) => (
                                        <Tag tag={tag} />
                                    )
                                }
                            />
                        </div>
                        <Button inverse onClick={() => {
                            onEdit(lesson)
                        }}>
                            <EditIcon /> {t(`lessons.edit`).toUpperCase()}
                        </Button>
                    </div>
                    <div className={styles.right}>
                        <img className={styles.thumbnail} src={lesson.thumbnail} alt="" />
                    </div>
                </>
            }



        </div>
    )
}

export default ModuleLessonCard

import { useDaily, useDailyError, useDevices, useMediaTrack, useParticipantProperty, useRoom, useScreenShare } from "@daily-co/daily-react";
import { useEffect, useState } from "react";
import { ReactComponent as CallPauseIcon } from "../../assets/images/icons/ic-call-pause.svg";
import { ReactComponent as CameraOffIcon } from "../../assets/images/icons/ic-camera-off.svg";
import { ReactComponent as CameraIcon } from "../../assets/images/icons/ic-camera.svg";
import { ReactComponent as ChatIconOff } from "../../assets/images/icons/ic-chat.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/icons/ic-check.svg";
import { ReactComponent as DoorIcon } from "../../assets/images/icons/ic-door.svg";
import { ReactComponent as WhiteboardIcon } from "../../assets/images/icons/ic-hand-draw.svg";
import { ReactComponent as MicOffIcon } from "../../assets/images/icons/ic-mic-off.svg";
import { ReactComponent as MicIcon } from "../../assets/images/icons/ic-mic.svg";
import { ReactComponent as FullScreenIcon } from "../../assets/images/icons/ic-screen-maximize.svg";
import { ReactComponent as FullScreenOffIcon } from "../../assets/images/icons/ic-screen-minimize.svg";
import { ReactComponent as ScreenIcon } from "../../assets/images/icons/ic-screenshare.svg";
import { ReactComponent as GearIcon } from "../../assets/images/icons/ic-settings.svg";
import { ReactComponent as UsersIcon } from "../../assets/images/icons/ic-users.svg";

import api from "../../api";
import { Each } from "../../common/Each";
import useSpeechDetection from "../../common/hooks/useSpeechDetection";
import { DialogStatus } from "../../enums";
import AlertDialog from "../dialogs/AlertDialog";
import DailyControl from "./DailyControl";
import styles from "./DailyControls.module.css";
import DailySpinner from "./DailySpinner";


const DailyControls = ({ 
    localSessionId, 
    showParticipants, 
    onShowParticipantsChange, 
    fullScreen,
    onFullScreenChange, 
    whiteboard, 
    onWhiteboardChange,
    chat, 
    onChatChange,
    unread,
    handsRaised, 
    waitingParticipants, 
    overlayMenu, 
    onOverlayMenuChange }) => {

    const call = useDaily()
    const room = useRoom()
    const localParticipant = useParticipantProperty(localSessionId, ['audio', 'video', 'user_name', 'userData', 'audioTrack'])
    const devices = useDevices()
    const { isSharingScreen, startScreenShare, stopScreenShare } = useScreenShare()
    const audioTrack = useMediaTrack(localSessionId, 'audio')
    const [loadingMic, setLoadingMic] = useState(null)
    const [loadingCam, setLoadingCam] = useState(null)

    const audioLevel = useSpeechDetection(audioTrack.track)
    const [endingCall, setEndingCall] = useState(false)
    const [permissionError, setPermissionError] = useState({ show: false, title: 'Errore', message: '' })
    const { nonFatalError } = useDailyError();

    useEffect(() => {
        if (nonFatalError && nonFatalError.type) {
            if (nonFatalError.type === 'screen-share-error') {
                setPermissionError({ show: true, title: 'Permessi Condivisione Schermo', message: `Sembra che tu non abbia acconsentito alla condivisione schermo. Per favore consenti i permessi dalle impostazioni del tuo browser e aggiorna la pagina.` })
            }
        }
    }, [nonFatalError])

    const endLesson = async () => {
        try {
            await api.put(`/teacher/rooms/${room.name}/end`)
        }
        catch (e) {
            console.error(e)
        }
    }

    async function checkPermissions(type) {
        try {
            if (!['camera', 'microphone'].includes(type)) {
                throw new Error("Wrong permission type.")
            }

            const permission = await navigator.permissions.query({ name: type });
            const isGranted = permission.state === 'granted';

            if (!isGranted) {
                await requestPermissions()
            }

        } catch (error) {
            console.error('Error checking permissions:', error);
        }
    }

    async function requestPermissions() {
        try {
            // Richiede l'accesso alla fotocamera e al microfono
            await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            console.log('Permessi concessi per fotocamera e microfono');
        } catch (error) {
            console.error('Permessi negati o errore nel richiedere i permessi:', error);
            setPermissionError({ show: true, title: 'Permessi Fotocamera e Microfono', message: `Sembra che tu non abbia acconsentito l'accesso alla fotocamera e al microfono. Per favore consenti i permessi dalle impostazioni del tuo browser e aggiorna la pagina.` })
            throw error
        }
    }


    return (
        <>
            <div className={styles.container}>
                <DailyControl
                    id="microphones"
                    active={localParticipant[0]}
                    icons={{
                        normal: <MicOffIcon className={styles.toolIcon} />,
                        active: <MicIcon className={styles.toolIcon} />
                    }}
                    onClick={() => {
                        checkPermissions('microphone')
                        call.setLocalAudio(!localParticipant[0])
                    }}
                    hasOption={devices.microphones.length > 1}
                    level={localParticipant[0] ? audioLevel : 0}
                >
                    <div className={styles.devices}>
                        <Each of={devices.microphones} render={(microphone) => (
                            <div className={styles.device} onClick={async (e) => {
                                e.stopPropagation()
                                setLoadingMic(microphone.device.deviceId)
                                await devices.setMicrophone(microphone.device.deviceId)
                                setLoadingMic(null)
                            }}>
                                <div className={styles.deviceSelected}>
                                    {microphone.selected &&
                                        <CheckIcon style={{ width: '12px', height: '12px' }} />
                                    }
                                    {loadingMic === microphone.device.deviceId &&
                                        <DailySpinner />
                                    }
                                </div>
                                <div className={styles.deviceLabel}>
                                    {microphone.device.label}
                                </div>
                            </div>
                        )} />
                    </div>

                </DailyControl>

                <DailyControl
                    id="cameras"
                    active={localParticipant[1]}
                    icons={{
                        normal: <CameraOffIcon className={styles.toolIcon} />,
                        active: <CameraIcon className={styles.toolIcon} />
                    }}
                    onClick={() => {
                        checkPermissions('camera')
                        call.setLocalVideo(!localParticipant[1])
                    }}
                    hasOption={devices.cameras.length > 1}
                >
                    <div className={styles.devices}>
                        <Each of={devices.cameras} render={(camera) => (
                            <div className={styles.device} onClick={async (e) => {
                                e.stopPropagation()
                                setLoadingCam(camera.device.deviceId)
                                await devices.setCamera(camera.device.deviceId)
                                setLoadingCam(null)
                            }}>
                                <div className={styles.deviceSelected}>
                                    {camera.selected &&
                                        <CheckIcon style={{ width: '12px', height: '12px' }} />
                                    }
                                    {loadingCam === camera.device.deviceId &&
                                        <DailySpinner />
                                    }
                                </div>
                                <div className={styles.deviceLabel}>
                                    {camera.device.label}
                                </div>
                            </div>
                        )} />
                    </div>

                </DailyControl>

                {call.meetingState() === "joined-meeting" &&
                    <>
                        <DailyControl
                            active={showParticipants}
                            icons={{
                                normal: <UsersIcon className={`${styles.toolIcon}`} />,
                                active: <UsersIcon className={`${styles.toolIcon} ${styles.active}`} />
                            }}
                            onClick={() => {
                                onShowParticipantsChange()
                            }}
                            tooltip={'Utenti'}
                            badgeNumber={handsRaised}
                        />

                        {waitingParticipants.length > 0 &&
                            <DailyControl
                                active={overlayMenu.open && overlayMenu.mode === 'waiting'}
                                icons={{
                                    normal: <CallPauseIcon className={`${styles.toolIcon}`} />,
                                    active: <CallPauseIcon className={`${styles.toolIcon} ${styles.active}`} />
                                }}
                                onClick={() => {
                                    onOverlayMenuChange('waiting')
                                }}
                                tooltip={`Lista d'attesa`}
                                badgeNumber={waitingParticipants.length}
                            />
                        }

                        <DailyControl
                            active={chat}
                            icons={{
                                normal: <ChatIconOff className={`${styles.toolIcon}`} />,
                                active: <ChatIconOff className={`${styles.toolIcon} ${styles.active}`} />
                            }}
                            onClick={() => {
                               onChatChange()
                            }}
                            tooltip="Chat"
                            badgeNumber={unread}
                        />

                        <DailyControl
                            active={isSharingScreen}
                            icons={{
                                normal: <ScreenIcon className={`${styles.toolIcon}`} />,
                                active: <ScreenIcon className={`${styles.toolIcon} ${styles.active}`} />
                            }}
                            onClick={() => {
                                isSharingScreen ? stopScreenShare() : startScreenShare()
                            }}
                            tooltip={isSharingScreen ? "Interrompi condivisione" : "Condividi schermo"}
                        />

                        <DailyControl
                            active={whiteboard}
                            icons={{
                                normal: <WhiteboardIcon className={`${styles.toolIcon}`} />,
                                active: <WhiteboardIcon className={`${styles.toolIcon} ${styles.active}`} />
                            }}
                            onClick={async () => {
                                onWhiteboardChange()
                            }}
                            tooltip={whiteboard ? "Chiudi" : 'Lavagna'}
                        />

                        <DailyControl
                            active={fullScreen}
                            icons={{
                                normal: <FullScreenIcon className={`${styles.toolIcon}`} />,
                                active: <FullScreenOffIcon className={`${styles.toolIcon} ${styles.active}`} />
                            }}
                            onClick={() => {
                               onFullScreenChange()
                            }}
                            tooltip={fullScreen ? "Riduci" : "Espandi"}
                        />


                        <DailyControl
                            active={overlayMenu.open && overlayMenu.mode === 'settings'}
                            icons={{
                                normal: <GearIcon className={`${styles.toolIcon}`} />,
                                active: <GearIcon className={`${styles.toolIcon} ${styles.active}`} />
                            }}
                            onClick={() => { onOverlayMenuChange('settings') }}
                            tooltip={'Impostazioni'}
                        />


                        <DailyControl
                            active={false}
                            icons={{
                                normal: <DoorIcon className={`${styles.toolIcon}`} />,
                                active: <DoorIcon className={`${styles.toolIcon} ${styles.active}`} />
                            }}
                            onClick={async () => {
                                setEndingCall(true)
                            }}
                            tooltip={"Termina Lezione"}
                        />
                    </>
                }
            </div>
            <AlertDialog
                open={endingCall}
                title={`Terminare la lezione ?`}
                text={`Tutti i partecipanti saranno rimossi e non sarà più possibile accedere alla lezione.`}
                onClose={() => {
                    setEndingCall(false)
                }}
                actions={[
                    {
                        label: "Termina",
                        onClick: async () => {
                            let updateList = {};
                            for (let id in call.participants()) {
                                if (id === 'local') {
                                    continue;
                                }
                                updateList[id] = { eject: true };
                            }
                            call.updateParticipants(updateList); //Rimuovi tutti i partecipanti
                            await endLesson() //Setta la lezione come conclusa
                            call.leave() //Lascia la chiamata
                            setEndingCall(false)
                        }
                    }
                ]}
            />
            <AlertDialog
                open={permissionError.show}
                errorMessage={permissionError.title}
                text={permissionError.message}
                status={DialogStatus.Error}
                onClose={() => {
                    setPermissionError({ show: false, title: 'Errore', message: '' })
                }}
            />
        </>
    )

}

export default DailyControls
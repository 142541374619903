import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactSortable } from "react-sortablejs"
import { Sortable, Swap } from "sortablejs"
import { v4 } from "uuid"
import { isTestChoiceValid, toLetter } from "../../utils"
import SortableContainer from "../SortableContainer"
import TagSelector from "../TagSelector"
import TextInput from "../TextInput"
import Card from "../cards/Card"
import Answer from "./Answer"
import styles from "./TestChoice.module.css"
Sortable.mount(new Swap());

const TestChoice = ({
    text = "",
    tags = [],
    answers = [{
        id: v4(),
        text: "",
        isCorrect: false
    }, {
        id: v4(),
        text: "",
        isCorrect: false
    }],
    tagList = [],
    onChange = () => { },
    singleChoice = false
}) => {
    const { t } = useTranslation()

    const [_answers, setAnswers] = useState(answers)
    const [filteredTagsList, setFilteredTagList] = useState(tagList)
    const [_text, setText] = useState(text)
    const [_tags, setTags] = useState(tags)

    useEffect(() => {
        setFilteredTagList(tagList)
    }, [tagList])

    useEffect(() => {
        const ids = _tags.map(t => t.id)
        if (ids.length > 0) {
            setFilteredTagList(tagList.filter(t => ids.includes(t.id) === false))
        } else {
            setFilteredTagList(tagList)
        }
    }, [_tags])

    const addAnswer = useCallback(() => {
        if (_answers.length >= 10) {
            return;
        }
        _answers.push({
            id: v4(),
            _text: "",
            isCorrect: false,
            label: null
        })
        setAnswers([..._answers])
    }, [_answers])

    const removeAnswer = useCallback((removedIndex) => {
        _answers.splice(removedIndex, 1);
        setAnswers([..._answers])
    }, [_answers])

    const changeAnswer = useCallback((answer) => {
        const index = _answers.findIndex(a => a.id === answer.id)
        if (index >= 0) {
            if (_answers[index].isCorrect === false && answer.isCorrect === true && singleChoice) {
                const isCorrectIdx = _answers.findIndex(a => a.isCorrect === true)
                if (isCorrectIdx !== -1 && index !== isCorrectIdx) {
                    _answers[isCorrectIdx].isCorrect = false
                }
            }

            _answers[index] = answer
            setAnswers([..._answers])
        }

    }, [_answers])

    useEffect(() => {
        const validation = isTestChoiceValid(_text, _answers)
        const { isValid, noQuestion, noAnswers, atLeastOneIsCorrect, atLeastOneIsIncorrect, isAnswerTextValid } = validation
        let validationError = ""
        if (noQuestion && !noAnswers && !atLeastOneIsCorrect && atLeastOneIsIncorrect && !isAnswerTextValid) {
            validationError = ""
        } else if (noQuestion) {
            validationError = t("tests.validation.noQuestion")
        } else if (noAnswers) {
            validationError = t("tests.validation.noAnswers")
        } else if (!atLeastOneIsCorrect && singleChoice) {
            validationError = t("tests.validation.noSolution")
        } else if (!atLeastOneIsCorrect && !singleChoice) {
            validationError = t("tests.validation.noSolutions")
        } else if (!atLeastOneIsIncorrect) {
            validationError = t("tests.validation.noErrors")
        } else if (!isAnswerTextValid) {
            validationError = t("tests.validation.noTextAnswers")
        }

        const changes = {
            answers: _answers,
            tags: _tags,
            text: _text,
            isValid,
            validationError,
        }
        onChange(changes)
    }, [_answers, _tags, _text])

    return (
        <div className={styles.container}>
            <Card hover style={{ padding: ".5rem 1rem", marginTop: ".5rem", backgroundColor: "var(--background-color)" }} title={t("tests.question")}>
                <div className={styles.innerCard}>
                    <TextInput
                        type={"text"}
                        value={_text}
                        onKeyUp={(value) => setText(value)}
                        style={{
                            padding: 0, border: "none", backgroundColor: "transparent",
                            fontSize: "1rem", fontWeight: 700, width: "100%",
                            borderRadius: 0
                        }} />
                </div>
            </Card>
            <TagSelector
                selected={_tags}
                options={filteredTagsList}
                placeholder={(_tags.length === 0 && t("tests.tagsPlaceholder")) || ""}
                onAdd={(item) => {
                    console.debug(item)
                    setTags(t => {
                        t.push(item)
                        return [...t]
                    })
                }}
                onRemove={(item) => {
                    setTags(t => {
                        t = t.filter((tt) => tt.id !== item.id)
                        return [...t]
                    })
                }}
            />
            <ReactSortable swap tag={SortableContainer} list={_answers} setList={setAnswers}>
                {
                    _answers.map(
                        (answer, index) => (
                            <Answer
                                key={answer.id}
                                id={answer.id}
                                text={answer.text}
                                isCorrect={answer.isCorrect}
                                label={answer.label || toLetter(index + 1).toUpperCase()}
                                onDelete={() => { removeAnswer(index) }}
                                onChange={(newAnswer) => { changeAnswer(newAnswer) }}
                            />
                        )
                    )
                }

            </ReactSortable>
            <div className={styles.addAnswer} onClick={addAnswer}>+ {t("tests.addAnswer")}</div>

        </div>
    )
}

export default TestChoice

import { useState } from "react"
import styles from "./Toggle.module.css"

const Toggle = ({checked, onChange = () => {}, loading=false}) => {

    const [check, setCheck] = useState(checked)

    return (
        <label className={styles.toggle}>
            <input disabled={loading} type="checkbox" checked={check} className={styles.toggleInput} onChange={(e) => {
                setCheck(!check)
                onChange(e.target.checked)
            }}/>
            <span className={styles.slider}></span>
        </label>
    )
}

export default Toggle
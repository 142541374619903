import {
    BoldItalicUnderlineToggles,
    diffSourcePlugin,
    MDXEditor,
    toolbarPlugin
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/ic-delete.svg"
import Checkbox from "../Checkbox"
import Card from "../cards/Card"
import styles from "./Answer.module.css"

const Answer = ({ id, label, onDelete = () => { }, onChange = () => { }, text = "", isCorrect = false }) => {

    const [_text, setText] = useState(text)
    const [_isCorrect, setIsCorrect] = useState(isCorrect)
    const { t } = useTranslation()

    useEffect(() => {
        onChange({ id, text: _text, isCorrect: _isCorrect })
    }, [_text, _isCorrect])

    useEffect(() => {
        if (text !== _text) {
            setText(text)
        }

        if (isCorrect !== _isCorrect) {
            setIsCorrect(isCorrect)
        }
    }, [text, isCorrect])

    return (
        <Card hover style={{ padding: ".5rem 1rem", width: "100%" }}>
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.label}>{label}</div>
                    <div className="_mdxeditor-inline">
                        <MDXEditor
                            className={styles.editor}
                            contentEditableClassName={styles.editorContent}
                            markdown={_text}
                            onChange={(value) => {
                                setText(value)
                            }}
                            placeholder={t("tests.answerPlaceholder")}
                            plugins={[
                                diffSourcePlugin(),
                                toolbarPlugin({
                                    toolbarContents: () => (
                                        <BoldItalicUnderlineToggles options={["Bold", "Italic"]} />
                                    )
                                })
                            ]}
                        />
                    </div>
                </div>
                <div className={styles.actions}>
                    <Checkbox value={_isCorrect} onChange={(value) => setIsCorrect(value)} />
                    <DeleteIcon className={styles.delete} onClick={onDelete} />
                </div>
            </div>
        </Card>
    )
}

export default Answer

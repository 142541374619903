import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg";
import { Each } from "../common/Each";
import typo from "../typography.module.css";
import Button from "./Button";
import Card from "./cards/Card";
import LessonCard from "./cards/LessonCard";
import styles from "./LessonsCarousel.module.css";
import Loader from "./Loader";

const LessonsCarousel = ({ lessons, loading }) => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const splideRef = useRef(null)

    return (
        <div>
            <Card style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                <div className={styles.header}>
                    <div className={typo.subtitle} style={{ display: 'flex', fontSize: '1rem' }}>{t('courses.nextLessons')}</div>
                    {lessons.length > 0 &&
                        <div className={styles.arrows}>
                            <button
                                className={`${styles.arrowButton} ${styles.left}`}
                                onClick={() => {
                                    if (splideRef && splideRef.current) {
                                        let index = splideRef.current.splide.index
                                        splideRef.current.splide.go(index - 1)
                                    }
                                }}
                            >
                                <ChevronIcon />
                            </button>
                            <button
                                className={`${styles.arrowButton} ${styles.right}`}
                                onClick={() => {
                                    if (splideRef && splideRef.current) {
                                        let index = splideRef.current.splide.index
                                        splideRef.current.splide.go(index + 1)
                                    }
                                }}>
                                <ChevronIcon />
                            </button>
                        </div>
                    }
                </div>
                {lessons.length > 0 && !loading &&
                    <Splide
                        id={styles.lessonCarousel}
                        ref={splideRef}
                        options={
                            {
                                perPage: 4,
                                gap: '1rem',
                                breakpoints: {
                                    1440: {
                                        perPage: 3,
                                    },
                                    1160: {
                                        perPage: 2,
                                    },
                                    540: {
                                        perPage: 1
                                    }
                                }
                            }
                        }
                    >
                        <Each of={lessons} render={(lesson) => {
                            return (
                                <SplideSlide>
                                    <LessonCard lesson={lesson} />
                                </SplideSlide>
                            )
                        }} />
                    </Splide>
                }
                {lessons.length === 0 && !loading &&
                    <div className={styles.noLessons}>
                        <div>{t('courses.noLessons')}<br></br>{t('courses.noLessonsAdvice')}</div>
                        <Button
                            onClick={() => { navigate("/messages") }}
                            style={{ display: 'flex', alignItems: 'center', padding: '.75rem 3rem' }} inverse>
                            {t('courses.contact').toUpperCase()}
                        </Button>

                    </div>
                }
                {loading &&
                    <div className={styles.loaderContainer}>
                        <Loader />
                    </div>
                }
            </Card>
        </div>
    )

}

export default LessonsCarousel
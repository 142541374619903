
import { useNavigate } from "react-router-dom"
import { ReactComponent as ChatIcon } from "../../assets/images/icons/ic-chat.svg"
import styles from "./StudentCard.module.css"
const { Buffer } = require("buffer")

const StudentCard = ({ student }) => {

  const navigate = useNavigate()

  return (
    <div className={styles.container}>
      <img className={styles.picture} src={student.picture} alt="" />
      <div className={styles.name}>{student.name} {student.surname}</div>
      <ChatIcon className={styles.chat}
        onClick={() => {
          const base64 = Buffer.from(`${student.id}_${student.name}_${student.surname}`, "utf-8").toString("base64")
          navigate(`/messages?chat=${base64}`)
        }}
      />
    </div>
  )
}

export default StudentCard

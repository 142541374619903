import { useCallback, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-arrow.svg";
import { ReactComponent as ExerciseIcon } from "../assets/images/icons/ic-exercise.svg";
import { ReactComponent as DocumentIcon } from "../assets/images/icons/ic-files-inactive.svg";
import { ReactComponent as PeopleIcon } from "../assets/images/icons/ic-people.svg";
import { ReactComponent as VideoLessonIcon } from "../assets/images/icons/ic-video-lesson.svg";
import { ReactComponent as TestIcon } from "../assets/images/icons/ic-test-inactive.svg";
import { Each } from "../common/Each";
import useWindowDimensions from "../common/hooks/useWindowDimensions";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import Skeleton from "../components/Skeleton";
import TabItem from "../components/TabItem";
import { ModulePages } from "../enums";
import typo from "../typography.module.css";
import styles from "./ModuleDrawer.module.css";
import ModuleExercises from "./ModuleExecises";
import ModuleFeed from "./ModuleFeed";
import ModuleLessons from "./ModuleLessons";
import ModuleMaterials from "./ModuleMaterials";
import ModuleStudents from "./ModuleStudents";
import Collapsable from "../components/Collapsable";
import { capitalize } from './../utils';
import LessonEdit from "./LessonEdit";
import ModuleTests from "./ModuleTests";
import Test from "./Test";
import SimpleCollapsable from "../components/SimpleCollapsable";

const ModuleDrawer = ({ defaultSelectedTab = null }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const { moduleId } = useParams()
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab)

  const [moduleName, setModuleName] = useState("")
  const [sectionName, setSectionName] = useState("")
  const [feed, setFeed] = useState([
    { loading: true },
    { loading: true },
  ])
  const [feedLoading, setFeedLoading] = useState(false)
  const [modules, setModules] = useState([])
  const [lessons, setLessons] = useState(null)
  const [students, setStudents] = useState(null)
  const [materials, setMaterials] = useState(null)
  const [exercises, setExercises] = useState(null)
  const [tests, setTests] = useState(null)
  const [selectedLesson, setSelectedLesson] = useState(null)
  const [test, setTest] = useState(null)
  const [tabs, setTabs] = useState(
    [
      { id: ModulePages.Students, icon: PeopleIcon, text: t("modules.students"), color: "var(--sf-purple)", route: `/courses/${moduleId}/students`, value: null },
      { id: ModulePages.Lessons, icon: VideoLessonIcon, text: t("modules.lessons"), color: "var(--sf-blue)", route: `/courses/${moduleId}/lessons`, value: null },
      { id: ModulePages.Materials, icon: DocumentIcon, text: t("modules.materials"), color: "var(--sf-green)", route: `/courses/${moduleId}/materials`, value: null },
      { id: ModulePages.Exercises, icon: ExerciseIcon, text: t("modules.exercises"), color: "var(--sf-yellow)", route: `/courses/${moduleId}/exercises`, value: null },
      { id: ModulePages.Tests, icon: TestIcon, text: t("modules.tests"), color: "var(--tertiary)", route: `/courses/${moduleId}/tests`, value: null },
    ]
  )

  useEffect(() => {
    setSelectedTab(defaultSelectedTab)
  }, [defaultSelectedTab])

  useEffect(() => {
    setSectionName(selectedTab && selectedTab !== ModulePages.LessonEdit ? t(`modules.${selectedTab}`) : "")
  }, [selectedTab])

  useEffect(() => {
    const getFeed = async () => {
      setFeedLoading(true)
      try {
        const module = await api.get(`/teacher/modules/${moduleId}/feed`);
        setFeed(module?.activities ?? [])
        setModuleName(module.name)
      } catch (e) {
        console.error(e)
        setFeed([])
      }
      setFeedLoading(false)
    }

    const getLessons = async () => {
      try {
        const lessons = await api.get(`/teacher/modules/${moduleId}/lessons`);
        setLessons(lessons)
      } catch (e) {
        console.error(e)
      }
    }

    const getStudents = async () => {
      try {
        const students = await api.get(`/teacher/modules/${moduleId}/students`);
        if (students) {
          setStudents(students)
        }
      } catch (e) {
        console.error(e)
      }
    }

    const getMaterials = async () => {
      try {
        const materials = await api.get(`/teacher/modules/${moduleId}/materials`)
        setMaterials(materials)
      } catch (e) {
        console.error(e)
      }
    }

    const getTests = async () => {
      try {
        const tests = await api.get(`/teacher/modules/${moduleId}/tests`)
        setTests(tests)
      } catch (e) {
        console.error(e)
      }
    }

    getFeed().then(() => {
      getStudents()
      getLessons()
      getMaterials()
      getExercises()
      getTests()
    })
  }, [])

  const getExercises = useCallback(async () => {
    try {
      const exercises = await api.get(`/teacher/modules/${moduleId}/exercises`)
      setExercises(exercises)
    } catch (e) {
      console.error(e)
    }
  }, [])

  const getModules = useCallback(async () => {
    try {
      const lessons = await api.get("/teacher/lessons");
      const lessonsPerModule = {}

      // group lessons by moduleId
      for (const lesson of lessons) {
        if (lesson.module.id in lessonsPerModule) {
          lessonsPerModule[lesson.module.id].lessons.push(lesson)
        } else {
          lessonsPerModule[lesson.module.id] = {
            id: lesson.module.id,
            label: lesson.module.name,
            lessons: [lesson]
          }
        }
      }
      const modules = Object.values(lessonsPerModule)
      modules.unshift({ id: null, label: t("tests.module.empty") })
      setModules(modules)
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (selectedTab === ModulePages.TestEdit && modules.length === 0) {
      getModules()
    }
  }, [selectedTab])

  const onChangeTabValue = useCallback((id, value) => {
    setTabs((prev) => {
      const idx = prev.findIndex((t) => t.id === id)
      if (idx >= 0) {
        prev[idx].value = value
      }

      return [...prev]
    })
  }, [tabs])

  const onLessonEdit = useCallback((lesson) => {
    setSelectedLesson(lesson)
    setSelectedTab(ModulePages.LessonEdit)
  }, [])

  const onTestEdit = useCallback((test) => {
    setTest(test)
    setSelectedTab(ModulePages.TestEdit)
  }, [])

  const onTestSave = useCallback(() => {
    setTest(null)
    setSelectedTab(ModulePages.Tests)
  }, [])

  const onTestBack = useCallback(() => {
    setTest(null)
    setSelectedTab(ModulePages.Tests)
  }, [])

  const onExerciseChange = useCallback(() => {
    getExercises()
  }, [])

  const onFeedChange = useCallback(async ({ activity_id, deleted = false, added = false }) => {

    if (added) {
      try {
        const activity = await api.get(`/teacher/modules/${moduleId}/feed/${activity_id}`);
        setFeed([activity, ...feed])
      } catch (e) {
        console.error(e)
      }
      return;
    }

    const idx = feed.findIndex(a => a.id === activity_id)
    if (idx === -1) {
      console.error("Activity not found")
      return;
    }

    if (deleted) {
      feed.splice(idx, 1)
      setFeed([...feed])
      return;
    }

    setFeed(p => {
      p[idx].loading = true
      return [...p]
    })
    try {
      const activity = await api.get(`/teacher/modules/${moduleId}/feed/${activity_id}`);
      setFeed(p => {
        p[idx] = activity
        return [...p]
      })
    } catch (e) {
      console.error(e)
      setFeed(p => {
        p[idx].loading = false
        return [...p]
      })
    }
  }, [feed])

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>
            {moduleName}
            {`${sectionName ? `/ ${capitalize(sectionName)}` : ""}`}
          </title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner} style={{ maxWidth: [ModulePages.LessonEdit, ModulePages.TestEdit].includes(selectedTab) ? "1440px" : "900px" }}>
            {selectedTab !== ModulePages.TestEdit &&
              <SimpleCollapsable
                expanded={selectedTab !== null}
                expadendHeight={"22px"}
              >
                <div
                  className={styles.back}
                  onClick={() => {
                    if (selectedTab === ModulePages.LessonEdit) {
                      navigate(`/courses/${moduleId}/lessons`, { replace: true })
                      setSelectedTab(ModulePages.Lessons)
                      setSelectedLesson(null)
                    } else {
                      navigate(`/courses/${moduleId}`, { replace: true })
                    }
                  }}>
                  <ArrowIcon /> <span>Torna indietro</span>
                </div>
              </SimpleCollapsable>
            }
            {feedLoading && <Skeleton type="rect" width="400px" height="44px" borderRadius="12px" />}
            {
              feedLoading === false && selectedTab !== ModulePages.LessonEdit && selectedTab !== ModulePages.TestEdit &&
              <div className={typo.title}>
                {moduleName}
                {sectionName && <span className={styles.sectionName}> / {capitalize(sectionName)} </span>}
              </div>
            }
            {
              selectedTab !== ModulePages.LessonEdit && selectedTab !== ModulePages.TestEdit &&
              <div className={styles.navBarContainer}>
                <Each
                  of={tabs}
                  render={(item, index) => (
                    <TabItem
                      selected={selectedTab === item.id}
                      text={capitalize(item.text)}
                      value={item.value}
                      IconComponent={item.icon}
                      color={item.color}
                      onClick={() => {
                        if (selectedTab !== item.id) {
                          setSelectedTab(item.id)
                          navigate(item.route, { replace: true })
                        } else {
                          navigate(`/courses/${moduleId}`, { replace: true })
                        }
                      }}
                    />
                  )
                  }
                />
              </div>
            }
            {!selectedTab && <ModuleFeed moduleId={moduleId} feed={feed} prefetchedLessons={lessons} onChange={onFeedChange} />}
            {selectedTab === ModulePages.Lessons && <ModuleLessons onChangeTabValue={onChangeTabValue} prefetchedLessons={lessons} onEdit={onLessonEdit} />}
            {selectedTab === ModulePages.Students && <ModuleStudents onChangeTabValue={onChangeTabValue} prefetchedStudents={students} />}
            {selectedTab === ModulePages.Materials && <ModuleMaterials onChangeTabValue={onChangeTabValue} prefetchedMaterials={materials} />}
            {selectedTab === ModulePages.Exercises && <ModuleExercises onChangeTabValue={onChangeTabValue} prefetchedExercises={exercises} onChange={onExerciseChange} />}
            {selectedTab === ModulePages.Tests && <ModuleTests onChangeTabValue={onChangeTabValue} onEdit={onTestEdit} prefetchedTests={tests} />}
            {selectedTab === ModulePages.LessonEdit && <LessonEdit lesson={selectedLesson} />}
            {selectedTab === ModulePages.TestEdit && <Test onBack={onTestBack} onSave={onTestSave} test={test} />}
          </div>
        </div>
      </div>
    </HeaderFooterLayout>
  );
};

export default ModuleDrawer;

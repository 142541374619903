import { useEffect, useState } from "react"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { Each } from "../common/Each"
import styles from "./Collapsable.module.css"

const Collapsable = ({ children = <></>, expanded = false, expadendHeight, style = {} }) => {

    const [exp, setExp] = useState(expanded)
    const [hiddenTimeout, setHiddenTimeout] = useState(null)
    const [overflow, setOverflow] = useState("visible")

    useEffect(() => {
        setExp(expanded ?? false)
        if (expanded) {
            setOverflow("hidden")
            setHiddenTimeout(setTimeout(() => {
                setOverflow("visible")
            }, 200))
        }
    }, [expanded])

    useEffect(() => {
        return () => {
            if (hiddenTimeout) {
                clearTimeout(hiddenTimeout)
            }
        }
    }, [])

    return (
        <div
            style={{
                ...style,
                overflow,
                height: exp ? expadendHeight : 0
            }}
            className={styles.container}
        >
            {children}
        </div>
    )
}

export default Collapsable

import { useState, useEffect } from 'react';

const useSpeechDetection = (audioTrack) => {
  const [isSpeaking, setIsSpeaking] = useState(false);

  useEffect(() => {
    if (!audioTrack) return;

    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(new MediaStream([audioTrack]));
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;
    source.connect(analyser);

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const detectSpeech = () => {
      analyser.getByteTimeDomainData(dataArray);
      let sum = 0;
      for (let i = 0; i < bufferLength; i++) {
        sum += Math.abs(dataArray[i] - 128);
      }
      const average = sum / bufferLength;
      // Puoi definire una soglia per determinare quando qualcuno sta parlando
      const audioLevel = average // Esempio di soglia
      setIsSpeaking(audioLevel);
    };

    const intervalId = setInterval(detectSpeech, 100); // Esegui la funzione di rilevamento ogni 100 millisecondi

    return () => {
      clearInterval(intervalId);
      audioContext.close();
    };
  }, [audioTrack]);

  return isSpeaking;
};

export default useSpeechDetection;
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg"
import { TestType } from "../../enums.js"
import DropdownSelection from "../DropdownSelection.js"
import TestChoice from "./TestChoice.js"
import Card from "../cards/Card.js"
import styles from "./TestForm.module.css"
import TestTrueFalse from "./TestTrueFalse.js"
import TestTextCompletion from "./TestTextCompletion.js"


const TestForm = ({
    testType = null,
    onRemove = () => { },
    innerTest = {},
    showRemoveButton,
    tagList = [],
    onChange = () => { }
}) => {
    const { t } = useTranslation()
    const [_testType, setTestType] = useState(testType)
    const [_innerTest, setInnerTest] = useState(innerTest)
    const [validationError, setValidationError] = useState("")

    const testTypes = useMemo(() => {
        return [
            { id: TestType.SingleChoice, label: t("tests.testType.singleChoice") },
            { id: TestType.MultipleChoice, label: t("tests.testType.multipleChoice") },
            { id: TestType.TrueFalse, label: t("tests.testType.trueFalse") },
            { id: TestType.TextCompletion, label: t("tests.testType.textCompletion.label") },
        ]
    }, [])

    const onInnerTestChange = useCallback((changes) => {
        const { validationError } = changes
        setValidationError(validationError)
        setInnerTest(changes)
    }, [])

    useEffect(() => {
        onChange({
            testType: _testType,
            innerTest: _innerTest
        })
    }, [_innerTest])

    useEffect(() => {
        setInnerTest({})
    }, [_testType])

    return (
        <Card style={{
            width: "100%", backgroundColor: "var(--background-color)",
        }} error={validationError}>
            <div className={styles.cardContent}>
                <div className={styles.header}>
                    <div className={styles.leftHeader}>
                        <div className={styles.testType}>
                            <DropdownSelection
                                defaultOptionIndex={testTypes.findIndex(t => t.id === testType)}
                                options={testTypes}
                                appereance="default"
                                onSelect={(index) => {
                                    if (index >= 0) {
                                        setTestType(testTypes[index].id)
                                    }
                                }
                                } />
                        </div>
                        {
                            validationError &&
                            <div className={styles.validationError}>
                                {validationError}
                            </div>
                        }
                    </div>
                    {
                        showRemoveButton &&
                        <button className={styles.closeButton} onClick={onRemove}>
                            <CloseIcon />
                        </button>
                    }
                </div>
                {
                    _testType === TestType.SingleChoice && <TestChoice tagList={tagList} {...innerTest} onChange={onInnerTestChange} singleChoice />
                }
                {
                    _testType === TestType.MultipleChoice && <TestChoice tagList={tagList} {...innerTest} onChange={onInnerTestChange} />
                }
                {
                    _testType === TestType.TrueFalse && <TestTrueFalse tagList={tagList} {...innerTest} onChange={onInnerTestChange} />
                }
                {
                    _testType === TestType.TextCompletion && <TestTextCompletion tagList={tagList} {...innerTest} onChange={onInnerTestChange} />
                }
            </div>
        </Card>
    )
}

export default TestForm

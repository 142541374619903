import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import { Each } from "../common/Each";
import useWindowDimensions from "../common/hooks/useWindowDimensions";
import TestCard from "../components/cards/TestCard";
import TextInput from "../components/TextInput";
import { ModulePages } from "../enums";
import styles from "./ModuleTests.module.css";


const ModuleTests = ({ onChangeTabValue = () => { }, prefetchedTests = null, onEdit = () => { } }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { moduleId } = useParams()

  const [tests, setTests] = useState(prefetchedTests)
  const [filteredTests, setFilteredTests] = useState(prefetchedTests)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!prefetchedTests) {
      getTests()
    }
  }, [])

  useEffect(() => {
    if (tests && tests.length > 0) {
      onChangeTabValue(ModulePages.Tests, tests.length);
      setFilteredTests(tests);
    }
  }, [tests]);

  useEffect(() => {
    return () => onChangeTabValue(ModulePages.Tests, null)
  }, [])

  const getTests = useCallback(async () => {
    setLoading(true)
    try {
      const tests = await api.get(`/teacher/modules/${moduleId}/tests`)
      const ts = tests.map(m => {
        return {
          ...m,
          module: { id: parseInt(moduleId) }
        }
      })
      setTests(tests)
      setFilteredTests(tests)
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }, [])

  const onSearch = useCallback(
    (text) => {
      if (tests && tests.length > 0) {
        if (text) {
          const filter = tests.filter((s) => {
            const target = `${s.name}`.toLowerCase().replaceAll(" ", "");
            const query = text ? text.toLowerCase().replaceAll(" ", "") : text
            return target.includes(query);
          });
          setFilteredTests(filter);
        } else {
          setFilteredTests(tests);
        }
      }
    },
    [tests]
  );

  const onDelete = useCallback(() => {
    getTests()
  }, [])

  const onComplete = useCallback(() => {
    getTests()
  }, [])

  return (
    <div className={styles.container}>
      <TextInput
        onKeyUp={onSearch}
        type="search"
        placeholder={"Cerca..."}
        style={{
          backgroundColor: "var(--background-secondary-color)",
        }}
      />
      <div className={styles.tests}>

        {
          loading === true &&
          <Each
            of={[0, 1]}
            render={() => (
              <div className={styles.test}>
                <TestCard loading />
              </div>
            )}

          />
        }

        {
          loading === false &&
          <Each
            of={filteredTests}
            render={(test) => (
              <div className={styles.test}>
                <TestCard test={test} onDelete={onDelete} onEdit={() => onEdit(test)} useVariant={true} onComplete={onComplete} />
              </div>
            )}
          />
        }
      </div>
    </div>
  );
};

export default ModuleTests;

import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import useWindowDimensions from "../common/hooks/useWindowDimensions";
import TextInput from "../components/TextInput";
import { DialogStatus, ModulePages } from "../enums";
import styles from "./ModuleExercises.module.css";
import { Each } from "../common/Each";
import ExerciseCard from "../components/cards/ExerciseCard";
import ExerciseDialog from "../components/dialogs/ExerciseDialog";


const ModuleExercises = ({ onChangeTabValue = () => { }, prefetchedExercises = null, onChange = () => { } }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { moduleId } = useParams()

  const [exercises, setExercises] = useState(prefetchedExercises)
  const [filteredExercises, setFilteredExercises] = useState(prefetchedExercises)
  const [loading, setLoading] = useState(false)
  const [dialogStatus, setDialogStatus] = useState(DialogStatus.Default)
  const [currentExercise, setCurrentExercise] = useState(null)
  const [dialogTimeout, setDialogTimeout] = useState(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const getExercises = async () => {
      setLoading(true)
      try {
        const exercises = await api.get(`/teacher/modules/${moduleId}/exercises`)
        setExercises(exercises.map(m => {
          return {
            ...m,
            module: { id: parseInt(moduleId) }
          }
        }))
      } catch (e) {
        console.error(e)
      }
      setLoading(false)
    }

    if (!prefetchedExercises) {
      getExercises()
    }
  }, [])

  useEffect(() => {
    setExercises(prefetchedExercises)
  }, [prefetchedExercises])

  useEffect(() => {
    if (exercises && exercises.length > 0) {
      onChangeTabValue(ModulePages.Exercises, exercises.length);
      setFilteredExercises(exercises);
    }
  }, [exercises]);

  useEffect(() => {
    return () => onChangeTabValue(ModulePages.Exercises, null)
  }, [])


  const onSearch = useCallback(
    (text) => {
      if (exercises && exercises.length > 0) {
        if (text) {
          const filter = exercises.filter((s) => {
            const target = `${s.name}`.toLowerCase().replaceAll(" ", "");
            const query = text ? text.toLowerCase().replaceAll(" ", "") : text
            return target.includes(query);
          });
          setFilteredExercises(filter);
        } else {
          setFilteredExercises(exercises);
        }
      }
    },
    [exercises]
  );

  const onDelete = useCallback((testId) => {
    onChange()
  }, [])

  const onEdit = useCallback((exercise) => {
    setCurrentExercise(exercise)
    setOpen(true)
  }, [])

  const onSave = useCallback(() => {
    setDialogTimeout(setTimeout(() => setOpen(false), 4000))
    onChange()
  }, [])

  return (
    <div className={styles.container}>
      <TextInput
        onKeyUp={onSearch}
        type="search"
        placeholder={"Cerca..."}
        style={{
          backgroundColor: "var(--background-secondary-color)",
        }}
      />
      <div className={styles.exercises}>
        {
          loading &&
          <Each
            of={[1, 2, 3]}
            render={() => (
              <div className={styles.exercise}>
                <ExerciseCard loading />
              </div>)}
          />
        }
        {
          loading === false &&
          <Each
            of={filteredExercises}
            render={(exercise) => (
              <div className={styles.exercise}>
                <ExerciseCard exercise={exercise} useVariant onEdit={() => onEdit(exercise)} onDelete={onDelete} />
              </div>
            )}
          />
        }
      </div>
      <ExerciseDialog
        open={open}
        onClose={() => {
          if (dialogTimeout) {
            clearTimeout(dialogTimeout)
            setDialogTimeout(null)
          }
          setOpen(false)
        }}
        exercise={currentExercise}
        status={dialogStatus}
        onSave={onSave}
      />
    </div>
  );
};

export default ModuleExercises;

import styles from "./Tag.module.css"

const Tag = ({ tag, IconComponent = null, onClickIcon = () => { }, onClick = () => { }, showIcon = false }) => {

    return (
        <div
            className={styles.container}
            style={{
                backgroundColor: `${tag.color}4D`,
                color: tag.color,
                fontSize: '0.625rem',
                fontWeight: 800
            }}
            onClick={onClick}
        >
            {tag.name}
            {IconComponent && showIcon && <IconComponent className={styles.icon} onClick={onClickIcon} />}
        </div>
    )
}

export default Tag

import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import { addDays, addHours, startOfDay, subDays, subSeconds } from "date-fns"
import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import api from "../api"
import { ReactComponent as BackofficeIcon } from "../assets/images/icons/ic-backoffice.svg"
import { ReactComponent as CalendarIcon } from "../assets/images/icons/ic-calendar.svg"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { ReactComponent as ClockIcon } from "../assets/images/icons/ic-clock.svg"
import { ReactComponent as ExerciseIcon } from "../assets/images/icons/ic-exercise-active.svg"
import { ReactComponent as LessonIcon } from "../assets/images/icons/ic-lessons-inactive.svg"
import { ReactComponent as StudentsIcon } from "../assets/images/icons/ic-students.svg"
import { Each } from "../common/Each"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import MainContext from "../common/MainContext"
import Button from "../components/Button"
import Card from "../components/cards/Card"
import ExerciseMaterialCard from "../components/cards/ExerciseMaterialCard"
import LessonCard from "../components/cards/LessonCard"
import TestCard from "../components/cards/TestCard"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Loader from "../components/Loader"
import Skeleton from "../components/Skeleton"
import typo from "../typography.module.css"
import { calcLastUpdate, chunkArray, formatDateV2, formatTimeV2 } from "../utils"
import styles from "./Dashboard.module.css"
import LessonsCarousel from "../components/LessonsCarousel"

const Dashboard = () => {

    const { t } = useTranslation()
    const context = useContext(MainContext)
    const navigate = useNavigate()

    const [lessons, setLessons] = useState([])
    const [lessonsLoading, setLessonsLoading] = useState(false)

    const [exercises, setExercises] = useState([])
    const [exercisesLoading, setExercisesLoading] = useState(false)
    const [submittedExercises, setSubmittedExercises] = useState([])
    const [submittedExercisesLoading, setSubmittedExercisesLoading] = useState(false)
    const [tests, setTests] = useState([])
    const [testsLoading, setTestsLoading] = useState(false)
    const [communications, setCommunications] = useState([])
    const [communicationsLoading, setCommunicationsLoading] = useState(false)
    const { width } = useWindowDimensions();

    const splideRef = useRef(null)

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })

        const getLessons = async () => {
            setLessonsLoading(true);
            try {
                let lessons = await api.get(`/teacher/lessons?ends_after=${new Date().toISOString()}`);
                setLessons(lessons.sort((a,b) => {
                    return new Date(a.starts_at) > new Date(b.starts_at)
                }))
            } catch (e) {
                console.error(e);
            }
            setLessonsLoading(false);
        };

        const getExercises = async () => {
            setExercisesLoading(true);
            try {
                const tomorrow = subSeconds(startOfDay(addDays(new Date(), 2)), 1).toISOString()

                const exercises = await api.get(`/teacher/exercises?expires_within=${tomorrow}`);
                setExercises(exercises);
            } catch (e) {
                console.error(e);
            }
            setExercisesLoading(false);
        }

        const getCommunications = async () => {
            setCommunicationsLoading(true);
            try {
                const threeDaysAgo = startOfDay(subDays(new Date(), 3)).toISOString()
                const communications = await api.get(`/teacher/activities/communications?start_after=${threeDaysAgo}`);
                setCommunications(communications);
            } catch (e) {
                console.error(e);
            }
            setCommunicationsLoading(false);
        }

        getExercises()
        getLessons()
        getSubmittedExercises()
        getTests()
        getCommunications()
    }, [])

    useEffect(() => {
        if (splideRef && splideRef.current) {
            splideRef.current.splide.go(0)
        }
    }, [splideRef])

    const getTests = useCallback(async () => {
        setTestsLoading(true);
        try {
            const tests = await api.get("/teacher/tests?status=completed&limit=3");
            setTests(tests);
        } catch (e) {
            console.error(e);
        }
        setTestsLoading(false);
    }, []);

    const getSubmittedExercises = useCallback(async () => {
        setSubmittedExercisesLoading(true)
        try {
            const submitted = await api.get(`/teacher/users/exercises`);
            setSubmittedExercises(submitted)
        } catch (e) {
            console.error(e);
        }
        setSubmittedExercisesLoading(false)
    }, [])

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Dashboard</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={typo.title}>
                            {t('dashboard.title', { name: context.user?.name ?? '' })}
                        </div>
                        <div className={styles.subtitle}>
                            {t('dashboard.subtitle')}
                        </div>
                        
                        <LessonsCarousel lessons={lessons} loading={lessonsLoading}/>

                        <div className={styles.asideContainer}>
                            <div className={styles.aside}>
                                <Card style={{ height: "100%" }}>
                                    <div className={styles.cardContent}>
                                        <div className={styles.cardTitleContainer}>
                                            <div className={styles.cardTitle}>{t("dashboard.expiringExercises")}</div>
                                            <div className={styles.seeAll}
                                                onClick={() => navigate("/exercises")}
                                            >
                                                {t("seeAll")}
                                            </div>
                                        </div>
                                        {
                                            exercisesLoading === false && exercises.length > 0 &&
                                            <Each
                                                of={exercises}
                                                render={(exercise) => (

                                                    <div className={styles.exercise}>
                                                        <div className={styles.exerciseHeader}>
                                                            <div className={styles.exerciseName}>
                                                                <div className={styles.exerciseIcon}>
                                                                    <ExerciseIcon />
                                                                </div>
                                                                {exercise.name}
                                                            </div>
                                                            <div className={new Date(exercise.expires_at) < addHours(new Date(), 12) ? `${styles.exerciseExpiresAt} ${styles.warning}` : styles.exerciseExpiresAt}>
                                                                {formatDateV2(exercise.expires_at)} {formatTimeV2(exercise.expires_at)} <CalendarIcon />
                                                            </div>
                                                        </div>
                                                        <div className={styles.exerciseRecap}>
                                                            <div className={styles.labeled}>
                                                                <div className={styles.label}>{t("exercises.submitNumber")}</div>
                                                                <div className={styles.value}><StudentsIcon className={styles.icon} />{exercise?.users_exercises_count} {t("outOf")} {exercise?.users ?? 0}</div>
                                                            </div>
                                                            <div className={styles.labeled}>
                                                                <div className={styles.label}>{t("exercises.lesson.label")}</div>
                                                                <div className={styles.value}><LessonIcon className={styles.icon} />{exercise?.lesson?.name ?? t("exercises.lesson.empty")}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        }

                                        {
                                            exercisesLoading &&
                                            <Each
                                                of={[0, 1, 2]}
                                                render={() => (
                                                    <div className={styles.exercise}>
                                                        <div className={styles.exerciseHeader}>
                                                            <div className={styles.exerciseName} style={{ width: "100%" }}>
                                                                <Skeleton type="rect" width="60%" height="18px" borderRadius="4px" />
                                                            </div>
                                                        </div>
                                                        <div className={styles.exerciseRecap} style={{ width: "100%" }}>
                                                            <div className={styles.labeled} style={{ width: "100%" }} >
                                                                <Skeleton type="rect" width="94%" height="16px" borderRadius="4px" />
                                                                <Skeleton type="rect" width="48%" height="16px" borderRadius="4px" />
                                                            </div>
                                                            <div className={styles.labeled} style={{ width: "100%" }} >
                                                                <Skeleton type="rect" width="44%" height="16px" borderRadius="4px" />
                                                                <Skeleton type="rect" width="82%" height="16px" borderRadius="4px" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        }

                                        {
                                            // TODO nessun esercizio
                                            exercisesLoading === false && exercises.length === 0 &&
                                            <div className={styles.noData}>Nessun esercizio in scadenza</div>
                                        }
                                    </div>
                                </Card>
                            </div>
                            <div className={styles.aside}>
                                <Card style={{ height: "100%" }}>
                                    <div className={styles.cardContent}>
                                        <div className={styles.cardTitleContainer}>
                                            <div className={styles.cardTitle}>{t("dashboard.communications")}</div>
                                        </div>
                                        {
                                            !communicationsLoading && communications.length > 0 &&
                                            <Splide
                                                ref={splideRef}
                                                aria-label="Communications" options={{
                                                    rewind: true,
                                                    autoplay: true,
                                                    speed: 1000
                                                }}>
                                                <Each
                                                    of={communications}
                                                    render={(activity) => (
                                                        <SplideSlide>
                                                            <div className={styles.activity}>
                                                                <div className={styles.activityHeader}>
                                                                    <BackofficeIcon className={styles.backoffice} />
                                                                    <div className={styles.activityTitle}>
                                                                        {t("backoffice")}
                                                                        <div className={styles.clock}><ClockIcon />{calcLastUpdate(activity.created_at, width > 768 ? false : true, true)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={styles.activityContent}>
                                                                    {activity.info?.message ?? ""}
                                                                </div>
                                                            </div>
                                                        </SplideSlide>
                                                    )}
                                                />
                                            </Splide>
                                        }
                                        {
                                            // TODO nessun esercizio
                                            !communicationsLoading && communications.length === 0 &&
                                            <div className={styles.noData}>Nessun annuncio</div>
                                        }
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <Card>
                            <div className={styles.cardContent}>
                                <div className={styles.cardTitleContainer}>
                                    <div className={styles.cardTitle}>{t("dashboard.submittedExercises")}</div>
                                    <div className={styles.seeAll}
                                        onClick={() => navigate("/exercises")}
                                    >
                                        {t("seeAll")}
                                    </div>
                                </div>
                                {
                                    submittedExercisesLoading === true &&
                                    <Each
                                        of={[0, 1, 2]}
                                        render={() => (
                                            <ExerciseMaterialCard loading />
                                        )}
                                    />
                                }

                                {
                                    !submittedExercisesLoading && submittedExercises.length > 0 &&
                                    <Each
                                        of={submittedExercises}
                                        render={(ue) => (
                                            <ExerciseMaterialCard exercise={ue} showExerciseInfo
                                                onCorrection={() => getSubmittedExercises()}
                                            />
                                        )}
                                    />
                                }

                                {
                                    // TODO nessun esercizio
                                    submittedExercisesLoading === false && submittedExercises.length === 0 &&
                                    <div className={styles.noData}>Nessun esercizio consegnato</div>
                                }
                            </div>
                        </Card>
                        <Card style={{ padding: "1rem 0.5rem" }}>
                            <div className={styles.cardContent}>
                                <div className={styles.cardTitleContainer} style={{ padding: "0 0.5rem" }}>
                                    <div className={styles.cardTitle}>{t("dashboard.completedTests")}</div>
                                    <div className={styles.seeAll}
                                        onClick={() => navigate("/tests")}
                                    >
                                        {t("seeAll")}
                                    </div>
                                </div>
                                <div className={styles.tests}>
                                    {
                                        testsLoading === true &&
                                        <Each
                                            of={[0, 1, 2]}
                                            render={() => (
                                                <TestCard loading />
                                            )}
                                        />
                                    }

                                    {
                                        !testsLoading && tests.length >= 0 &&
                                        <Each
                                            of={tests}
                                            render={(test) => (
                                                <div className={styles.test}>
                                                    <TestCard test={test} onDelete={getTests} />
                                                </div>
                                            )}
                                        />
                                    }
                                    {
                                        // TODO nessun esercizio
                                        testsLoading === false && tests.length === 0 &&
                                        <div className={styles.noData}>Nessun test completato</div>
                                    }
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div >
        </HeaderFooterLayout >
    )
}

export default Dashboard

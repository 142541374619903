
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Each } from "../../common/Each"
import { CourseStatus, CourseType } from "../../common/constants"
import typo from "../../typography.module.css"
import Button from "../Button"
import CourseBadge from "../CourseBadge"
import styles from "./TeacherCourseCard.module.css"
import { ReactComponent as PeopleIcon } from "../../assets/images/icons/ic-people.svg"

const TeacherCourseCard = ({ edition }) => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={edition.course.type === CourseType.Masterclass ? styles.noWrapper : styles.wrapper}>
      {edition.course.type === CourseType.Professional &&
        <div className={typo.subheadline}>{edition.course.name} - {edition.name}</div>
      }
      <Each
        of={edition.modules}
        render={(module, index) => (
          <div className={styles.container}>
            <div className={styles.left}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                {
                  edition.course.type === CourseType.Professional &&
                  <div className={typo.subheadline}>{module.name}</div>
                }
                {
                  edition.course.type === CourseType.Masterclass &&
                  <div className={typo.subheadline}>{edition.course.name} - {edition.name}</div>
                }
                <CourseBadge type={edition.course.type} style={{ width: 'fit-content' }} />
              </div>
              <div className={`${typo.body} ${styles.description}`} style={{ opacity: 0.4 }}>{module.description}</div>
              <Button inverse={edition.course.status !== CourseStatus.ToStart} onClick={() => {
                navigate(`/courses/${module.id}`)
              }}>
                {t('courses.enter').toUpperCase()}
              </Button>
            </div>
            <div className={styles.right}>
              <div className={styles.thumbnail}>
                <img src={edition.course.thumbnail} alt="course-thumbnail"></img>
              </div>
              <div className={styles.students}>
                <PeopleIcon />
                <div className={styles.studentsCounter}>
                  {edition.students}
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  )

}

export default TeacherCourseCard

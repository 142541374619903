import { useTranslation } from "react-i18next"
import styles from "./TrueFalseBadge.module.css"

const TrueFalseBadge = ({ type = false, value = null, onChange = () => { }, style = {} }) => {
  const { t } = useTranslation()

  return (
    <>
      {
        !type &&
        <div
          onClick={() => {
            if (!onChange) {
              return
            }

            if (value === null) {
              onChange(false)
            } else {
              onChange(!value)
            }
          }}
          style={style}
          className={value === false ? `${styles.container} ${styles.false} ${styles.checked} ${onChange === null ? styles.static : ""}` : `${styles.container} ${styles.false} ${onChange === null ? styles.static : ""}`}>
          {t("false").toUpperCase()}
        </div>
      }
      {
        type &&
        <div
          onClick={() => {
            if (onChange) {
              onChange(!value)
            }
          }}
          style={style}
          className={value ? `${styles.container} ${styles.true} ${styles.checked} ${onChange === null ? styles.static : ""}` : `${styles.container} ${styles.true} ${onChange === null ? styles.static : ""}`}>
          {t("true").toUpperCase()}
        </div>
      }
    </>
  )
}


export default TrueFalseBadge

import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import { ReactComponent as DownloadIcon } from "../../assets/images/icons/ic-download.svg"
import { ReactComponent as ThumbnailExcel } from "../../assets/images/illustrations/il-thumbnail-excel.svg"
import { ReactComponent as ThumbnailGeneric } from "../../assets/images/illustrations/il-thumbnail-generic.svg"
import { ReactComponent as ThumbnailMulti } from "../../assets/images/illustrations/il-thumbnail-multi.svg"
import { ReactComponent as ThumbnailPdf } from "../../assets/images/illustrations/il-thumbnail-pdf.svg"
import { ReactComponent as ThumbnailPpt } from "../../assets/images/illustrations/il-thumbnail-ppt.svg"
import { ReactComponent as ThumbnailText } from "../../assets/images/illustrations/il-thumbnail-text.svg"
import { ReactComponent as ThumbnailVideo } from "../../assets/images/illustrations/il-thumbnail-video.svg"
import { ReactComponent as ExerciseIcon } from "../../assets/images/icons/ic-exercise-active.svg"
import { ReactComponent as LessonIcon } from "../../assets/images/icons/ic-lessons-inactive.svg"
import { ReactComponent as InsertDocumentIllustration } from "../../assets/images/illustrations/il-insert-document.svg"
import { ReactComponent as AddIcon } from "../../assets/images/icons/ic-add-circle.svg"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import { formatDateV2, formatFileSize, formatTimeV2, getLocalDate } from "../../utils"
import Button from "../Button"
import styles from "./ExerciseMaterialCard.module.css"
import api from "../../api"
import Skeleton from "../Skeleton"
import Dialog from "../dialogs/Dialog"
import Card from "./Card"
import { DialogStatus } from "../../enums"
import TextareaAutosize from 'react-textarea-autosize'
import { Each } from "../../common/Each"
import MaterialButton from "../MaterialButton"
import Dropzone from "react-dropzone"
import { v4 } from "uuid"

const supportedImageExtensions = ["jpg", "jpeg", "png", "svg", "webp", "gif", "avif", "apng"]
const supportedVideoExtensions = ["mp4", "m4p", "mov", "flv", "mkv", "avi", "wmv", "mpg", "mpeg", "m2v"]

const mediaExtensions = ["jpg", "jpeg", "png", "svg", "webp", "gif", "avif", "apng", "mp4", "m4p", "mov", "flv", "mkv", "avi", "wmv", "mpg", "mpeg", "m2v"]
const pptExtensions = [".ppt"]
const excelExtensions = [".xlsx"]
const textExtensions = [".docx"]

const ExerciseMaterialCard = ({ exercise, loading = false, onCorrection = () => { }, showExerciseInfo }) => {
    const { width } = useWindowDimensions()
    const { t } = useTranslation()

    const [openCorrectionDialog, setOpenCorrectionDialog] = useState(false)
    const [correctionMessage, setCorrectionMessage] = useState("")
    const [dialogStatus, setDialogStatus] = useState(DialogStatus.Default)
    const [files, setFiles] = useState([])
    const dropzoneRef = useRef(null)

    const material = useMemo(() => {
        if (!exercise) {
            return null
        }
        if (exercise.medias.length === 0) {
            return null
        }

        if (exercise.medias.length === 1) {
            return exercise.medias[0]
        }

        return {
            multiple: true,
            url: "",
            name: t("exercises.multipleMaterials"),
            size: exercise.medias.map(m => m.size).reduce((a, c) => a += c, 0),
            created_at: exercise.medias[0].created_at
        }
    }, [exercise])

    const isCorrectionAvailable = useMemo(() => {
        if (!exercise) return false

        const { expires_at } = exercise.exercise

        console.debug({ expires_at, de: getLocalDate(expires_at), d: new Date(), c: getLocalDate(expires_at) < new Date() })
        return expires_at && getLocalDate(expires_at) < new Date()
    }, [exercise])

    const Thumbnail = useMemo(() => {
        if (material) {
            if (material.multiple) {
                return ThumbnailMulti
            }

            const fileExtension = material.url.split(".").slice(-1)[0].toLowerCase()
            if (mediaExtensions.includes(fileExtension)) {
                return ThumbnailVideo
            }
            if (pptExtensions.includes(fileExtension)) {
                return ThumbnailPpt
            }
            if (pptExtensions.includes(fileExtension)) {
                return ThumbnailPpt
            }
            if (excelExtensions.includes(fileExtension)) {
                return ThumbnailExcel
            }
            if (textExtensions.includes(fileExtension)) {
                return ThumbnailText
            }
            if (fileExtension === "pdf") {
                return ThumbnailPdf
            }
        }
        return ThumbnailGeneric
    }, [material])

    const onDownload = useCallback(async () => {
        for (const media of exercise.medias) {
            const link = document.createElement("a");
            link.setAttribute("id", media.id)
            link.setAttribute("href", media.url)
            document.body.appendChild(link);
        }

        for (const media of exercise.medias) {
            const e = document.getElementById(media.id)
            await new Promise(r => setTimeout(r, 500))
            e.click()
        }

    }, [exercise]);


    const sendCorrection = useCallback(async () => {
        setDialogStatus(DialogStatus.Loading)
        try {
            const { user } = exercise
            const { id: user_id } = user
            const form = new FormData()
            form.append("message", correctionMessage)
            form.append("user_id", user_id)
            for (let i = 0; i < files.length; i++) {
                form.append('files', files[i]);
            }
            await api.post(`/teacher/exercises/${exercise.exercise.id}`, form, { headers: { "Content-Type": "multipart/form-data" } })
            setDialogStatus(DialogStatus.Success)
            onCorrection()
        } catch (e) {
            console.error(e)
            setDialogStatus(DialogStatus.Error)
        }

    }, [files, correctionMessage, exercise, onCorrection])

    return (
        <div className={styles.container}>

            {
                loading === false &&
                <>
                    <div className={styles.left}>
                        <Thumbnail className={styles.thumbnail} />
                        <div className={styles.material}>
                            <div className={styles.name}>{material.name}</div>
                            <div className={styles.size}>{formatFileSize(material.size)}</div>
                        </div>
                    </div>
                    <div className={styles.user}>
                        <img className={styles.picture} src={exercise.user.picture} alt="" />
                        <div className={styles.username}>{exercise.user.name} {exercise.user.surname}</div>
                    </div>
                    {
                        showExerciseInfo &&
                        // {
                        //     // TODO Tooltip
                        // }
                        <div className={styles.exerciseInfoContainer}>
                            <div className={styles.exerciseInfo}><ExerciseIcon /> {exercise.exercise.name}</div>
                            <div className={styles.exerciseInfo}><LessonIcon /> {exercise.lesson?.name ?? t("exercises.lesson.empty")}</div>
                        </div>
                    }
                    <div className={styles.action}>
                        {
                            material.created_at &&
                            <div className={styles.time}>
                                {formatTimeV2(parseInt(material.created_at * 1000))}, {formatDateV2(parseInt(material.created_at * 1000))} <ClockIcon className={styles.icon} />
                            </div>
                        }
                        <button className={styles.download} onClick={onDownload}>
                            <DownloadIcon />
                        </button>

                        <Button
                            inverse={exercise.correction !== null}
                            disabled={exercise.correction !== null || isCorrectionAvailable === false}
                            accentColor={"var(--tertiary)"}
                            onClick={() => setOpenCorrectionDialog(true)}
                            style={{ padding: ".6rem 1.5rem" }}
                        >
                            {
                                exercise.correction !== null &&
                                <>{t("exercises.correctionDone").toUpperCase()}</>
                            }
                            {
                                exercise.correction === null &&
                                <>{t("exercises.correctAction").toUpperCase()}</>
                            }
                        </Button>
                    </div>
                </>
            }

            {
                loading === true &&
                <>
                    <div className={styles.left} style={{ alignItems: "center" }}>
                        <Skeleton type="rect" width="100px" height="56px" borderRadius="6px" />
                        <div className={styles.material} style={{ justifyContent: "center", gap: ".2rem" }}>
                            <Skeleton type="rect" width="196px" height="16px" borderRadius="4px" />
                            <Skeleton type="rect" width="96px" height="16px" borderRadius="4px" />
                        </div>
                    </div>
                    <div className={styles.user}>
                        <Skeleton type="circle" width="40px" height="40px" />
                        <Skeleton type="rect" width="96px" height="16px" borderRadius="4px" />
                    </div>
                    <div className={styles.action}>
                        <Skeleton type="rect" width="132px" height="16px" borderRadius="4px" />
                        <Skeleton type="rect" width="32px" height="32px" borderRadius="8px" />
                        <Skeleton type="rect" width="120px" height="40px" borderRadius="120px" />
                    </div>
                </>
            }


            <Dialog
                open={openCorrectionDialog}
                title={t("exercises.correctionTitle")}
                style={{ maxWidth: "512px" }}
                status={dialogStatus}
                onClose={() => {
                    setOpenCorrectionDialog(false)
                    setFiles([])
                    setCorrectionMessage("")
                    setDialogStatus(DialogStatus.Default)
                }}
                action={
                    <Button onClick={sendCorrection} disabled={dialogStatus !== DialogStatus.Default}>
                        {t("send").toUpperCase()}
                    </Button>
                }
            >
                <div className={styles.dialog}>
                    <div className={styles.helperText}>{t("exercises.helperText")}</div>
                    <Card hover style={{ padding: ".5rem 1rem" }}>
                        <div className={styles.textAreaContainer}>
                            <TextareaAutosize
                                value={correctionMessage}
                                minRows={3} maxRows={10}
                                type="text"
                                placeholder={t("exercises.correctionText")}
                                className={styles.textArea}
                                onChange={(e) => {
                                    const { value } = e.target
                                    setCorrectionMessage(value)
                                }} />
                        </div>
                    </Card>
                    <div className={styles.dropzoneContainer}>
                        <div className={styles.subtitle} >
                            {t("exercises.corrections")}
                            {files.length > 0 &&
                                <AddIcon style={{ cursor: "pointer", color: "var(--tertiary)" }} onClick={() => {
                                    if (dropzoneRef && dropzoneRef.current) {
                                        dropzoneRef.current.click();
                                    }
                                }} />
                            }
                        </div>
                        {
                            files.length > 0 && dialogStatus === DialogStatus.Default &&
                            <div className={styles.filesContainer}>
                                <div className={styles.files}>
                                    <Each
                                        of={files}
                                        render={(file) => (
                                            <div className={styles.file}>
                                                <MaterialButton
                                                    material={file}
                                                    onClick={() => {
                                                        setFiles((prevFiles) => {
                                                            const idx = prevFiles.findIndex(f => f.id === file.id)
                                                            if (idx > -1) prevFiles.splice(idx, 1)
                                                            return [...prevFiles]
                                                        })
                                                    }}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        }
                        <div style={{
                            display: files.length === 0 && dialogStatus === DialogStatus.Default ? "flex" : "none",
                        }}>
                            <Dropzone
                                onDrop={(newFiles) => {
                                    newFiles.forEach(f => f.id = v4())
                                    setFiles([...files, ...newFiles])
                                }}>
                                {({ getRootProps, getInputProps }) => (
                                    <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                                        <div {...getRootProps()} style={{ display: 'flex', width: '100%' }} ref={dropzoneRef}>
                                            <input {...getInputProps()} />
                                            <div className={styles.dropzone}>
                                                <InsertDocumentIllustration />
                                                <div className={styles.dropzoneLabel}>
                                                    {t("materials.dragAndDrop")}
                                                </div>
                                                <Button
                                                    style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                                    accentColor={"var(--tertiary)"}
                                                    onClick={() => dropzoneRef.current.click()}
                                                >
                                                    {t("materials.selectFile")}
                                                </Button>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>

    )
}

export default ExerciseMaterialCard

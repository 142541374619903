import styles from "./StudentTestResult.module.css"
import Back from "../components/Back"
import { Each } from "../common/Each"
import Card from "../components/cards/Card"
import { toLetter } from "../utils"
import { useTranslation } from "react-i18next"
import { ReactComponent as CrossIcon } from "../assets/images/icons/ic-close.svg"
import { ReactComponent as CheckIcon } from "../assets/images/icons/ic-check.svg"
import { ReactComponent as ChatIcon } from "../assets/images/icons/ic-chat.svg"
import UserPlaceholder from "../assets/images/placeholders/user-placeholder.png"
import Button from "../components/Button"
import { useMemo, useState } from "react"
import CircularProgess from "../components/CircularProgress"
import { TestType } from "../enums"
import TrueFalseBadge from "../components/tests/TrueFalseBadge"
import ReactMarkdown from "react-markdown"

const StudentTestResult = ({ student, test, onBack = () => { }, onContact = () => { } }) => {

  const { t } = useTranslation()
  const [circularContent, setCircularContent] = useState(null)

  const circularData = useMemo(() => {

    let correct = 0
    let wrong = 0
    let notAnswered = 0
    const successThreshold = test.success_threshold ?? 0
    for (const innerTest of test) {
      let innerTestDone = false
      const { testType } = innerTest
      switch (testType) {
        case TestType.SingleChoice:
        case TestType.MultipleChoice: {
          let c = 0
          let w = 0
          let givenResponse = 0
          for (const answer of innerTest.answers) {
            const isStudentAnswer = answer.students.map((s) => s.id).includes(student.id)
            if (answer.isCorrect && isStudentAnswer) {
              c += 1
              givenResponse += 1
            } else if (!answer.isCorrect && isStudentAnswer) {
              w += 1
              givenResponse += 1
            }
          }
          correct += c
          wrong += w
          const nCorrect = innerTest.answers.filter(a => a.isCorrect).map(a => a.id).length
          if (nCorrect > givenResponse) {
            notAnswered += nCorrect - givenResponse
            console.debug({ testType, na: nCorrect - givenResponse })
          }
        } break;

        case TestType.TrueFalse: {
          const { answers } = innerTest
          for (const answer of answers) {
            const { usersAnswers } = answer
            const userAnswer = usersAnswers.find(ua => ua.user.id === student.id)
            if (userAnswer?.isCorrect) {
              correct += 1
            } else if (!userAnswer?.isCorrect && userAnswer.value !== null) {
              wrong += 1
            } else {
              notAnswered += 1
            }
          }
        } break
        case TestType.TextCompletion: {
          const { words } = innerTest
          const unknowns = words.filter(w => w.hidden)
          for (const word of unknowns) {
            const { usersAnswers } = word
            const userAnswer = usersAnswers.find(ua => ua.user.id === student.id)
            if (userAnswer && userAnswer.isCorrect) {
              correct += 1
            } else if (userAnswer && userAnswer.isCorrect === false && userAnswer.answer !== "" && userAnswer.answer !== null) {
              wrong += 1
            } else {
              notAnswered += 1
            }
          }
        } break
        default: console.error("Unsupported test type"); break
      }
    }

    const sum = correct + wrong + notAnswered

    const pc = Math.round(((correct) / sum * 100) * 100) / 100
    const pw = Math.round(((wrong) / sum * 100) * 100) / 100
    const pna = Math.round(((notAnswered) / sum * 100) * 100) / 100

    if ((correct + wrong) === 0) {
      setCircularContent({
        label: t("tests.status.incomplete"), style: {
          fontSize: "1.125rem",
          fontWeight: 800,
          fill: "#B5B8BE"
        }
      })
    } else if (correct >= successThreshold) {
      setCircularContent({
        label: t("tests.status.passed"), style: {
          fontSize: "1.5rem",
          fontWeight: 800,
          fill: "var(--sf-light-green)"
        }
      })
    } else {
      setCircularContent({
        label: t("tests.status.failed"), style: {
          fontSize: "1.5rem",
          fontWeight: 800,
          fill: "var(--sf-red)"
        }
      })
    }

    return [
      {
        id: "correct",
        value: pc,
        rawValue: correct,
        color: 'var(--sf-light-green)',
        label: t("tests.correctAnswers")
      },
      {
        id: "wrong",
        value: pw,
        rawValue: wrong,
        color: 'var(--sf-red)',
        label: t("tests.wrongAnswers")
      },
      {
        id: "notAnswered",
        value: pna,
        rawValue: notAnswered,
        color: '#B5B8BE',
        label: t("tests.notAnswered")
      },
    ]
  }, [test])

  return (
    <div className={styles.container}>
      <Back onClick={onBack} />

      <div className={styles.top}>
        <Card style={{ flexGrow: 1 }}>
          <div className={styles.studentCard}>
            <div className={styles.cardTitle}>{t("tests.student")}</div>
            <div className={styles.student}>
              <img className={styles.picture} src={student.picture ?? UserPlaceholder} alt="" />
              <div className={styles.name}>{student.name} {student.surname}</div>
              <Button
                accentColor="var(--tertiary)"
                onClick={onContact}
                style={{ padding: "0.5rem 1rem" }}
              >
                <ChatIcon style={{ width: "24px", height: "24px" }} />
                {t("tests.contact").toUpperCase()}
              </Button>
            </div>

          </div>
        </Card>
        <Card style={{ flexGrow: 1 }}>
          <div className={styles.recap}>
            <div className={styles.cardTitle}>{t("tests.recapTitle")}</div>
            <div className={styles.circularChartColumn}>
              <CircularProgess content={circularContent} data={circularData.filter(d => d.id !== "notAnswered")}></CircularProgess>
              <div className={styles.chartLabels}>
                {circularData.map(data => {
                  return (
                    <div className={styles.chartCell} key={data.label}>
                      <div className={styles.chartRow}>
                        <div className={styles.chartColumn}>
                          <div className={styles.chartColor} style={{ backgroundColor: data.color }}></div>
                        </div>
                        <div className={styles.chartColumn}>
                          <div className={styles.chartDataTitle}>{data.rawValue}</div>
                          <div className={styles.chartDataSubitle} style={{ color: data.color }}>{data.label}</div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className={styles.content}>
        <Each
          of={test}
          render={(innerTest) => (
            <Card style={{ flexGrow: 1, padding: 0 }}>
              <div className={styles.innerTest}>

                {
                  innerTest.text &&
                  <div className={styles.cardTitle} style={{
                    padding: "0 1rem",
                    paddingTop: "1rem",
                  }}>{innerTest.text}</div>
                }
                {
                  (innerTest.testType === TestType.SingleChoice || innerTest.testType === TestType.MultipleChoice) &&
                  <Each
                    of={innerTest.answers}
                    render={(answer, index) => {
                      const isStudentAnswer = answer.students.map((s) => s.id).includes(student.id)
                      let resultBadge = null
                      let answerClass = answer.isCorrect ? `${styles.answer} ${styles.correct}` : styles.answer
                      if (isStudentAnswer && answer.isCorrect) {
                        resultBadge = {
                          label: t("tests.correct"),
                          Icon: CheckIcon,
                          class: `${styles.badge} ${styles.correct}`
                        }

                      } else if (isStudentAnswer && !answer.isCorrect) {
                        answerClass = `${styles.answer} ${styles.wrong}`
                        resultBadge = {
                          label: t("tests.wrong"),
                          Icon: CrossIcon,
                          class: `${styles.badge} ${styles.wrong}`
                        }
                      }

                      return (
                        <div className={styles.answerContainer}>
                          <div className={styles.answerWrapper}>
                            <div className={answerClass}>
                              <div className={styles.left}>
                                <div className={answer.isCorrect ? styles.correctLabel : styles.wrongLabel}>{toLetter(index + 1).toUpperCase()}</div>
                                <ReactMarkdown children={answer.text} />
                              </div>
                              {
                                resultBadge !== null &&
                                <div className={resultBadge.class}>{resultBadge.label} <resultBadge.Icon style={{ width: "16px", height: "16px" }} /></div>
                              }
                            </div>

                          </div>
                        </div>
                      )
                    }}
                  />
                }
                {
                  innerTest.testType === TestType.TrueFalse &&
                  <Each
                    of={innerTest.answers}
                    render={(answer) => (
                      <div className={styles.answerContainer} >
                        <div className={styles.answerWrapper}>
                          <div className={answer.usersAnswers.find(ua => ua.user.id === student.id)?.isCorrect ? styles.answer : `${styles.answer} ${styles.wrong}`}>
                            <ReactMarkdown children={answer.text} />
                            <div className={styles.badges}>
                              <TrueFalseBadge type={true} onChange={null} value={answer.value} />
                              <TrueFalseBadge type={false} onChange={null} value={answer.value} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  />
                }
                {
                  innerTest.testType === TestType.TextCompletion &&
                  <div className={styles.textCompletion}>
                    <div className={styles.words}>
                      <Each
                        of={innerTest.words}
                        render={(word) => {
                          if (!word.hidden) {
                            return <div className={styles.word}>{word.text}</div>
                          } else {
                            const userAnswer = word.usersAnswers.find(ua => ua.user.id === student.id)
                            if (word.hidden && !userAnswer.isCorrect) {
                              return <div className={`${styles.hiddenWord} ${styles.wrong}`}><span>{userAnswer.answer}</span> <span>{word.solutions[0]}</span></div>
                            } else if (word.hidden && userAnswer.isCorrect) {
                              return <div className={styles.hiddenWord}>{userAnswer.answer}</div>
                            }
                          }
                        }}
                      />
                    </div>
                    <div className={styles.solutions}>
                      <Each
                        of={innerTest.words.filter(w => w.hidden)}
                        render={(hiddenWord, index) => (
                          <div className={styles.solutionContainer}>
                            <div>{`${index + 1}. `}{hiddenWord.usersAnswers.find(ua => ua.user.id === student.id).answer}</div>
                            {hiddenWord.usersAnswers.find(ua => ua.user.id === student.id).answer && "->"}
                            <div className={styles.solution}>{hiddenWord.solutions.join(", ")}</div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                }
              </div>
            </Card>

          )}
        />
      </div>
    </div >
  )

}

export default StudentTestResult

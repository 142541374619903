import { useCallback, useContext, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { ReactComponent as QualificationIcon } from "../assets/images/icons/ic-bag.svg";
import { ReactComponent as CorrectionsIcon } from "../assets/images/icons/ic-corrections.svg";
import { ReactComponent as HourglassIcon } from "../assets/images/icons/ic-duration.svg";
import { ReactComponent as EmailIcon } from "../assets/images/icons/ic-email.svg";
import { ReactComponent as ExerciseIcon } from "../assets/images/icons/ic-exercise-active.svg";
import { ReactComponent as MaterialsIcon } from "../assets/images/icons/ic-files-active.svg";
import { ReactComponent as EditIcon } from "../assets/images/icons/ic-image-edit.svg";
import { ReactComponent as BioIcon } from "../assets/images/icons/ic-knowledge.svg";
import { ReactComponent as LogoutIcon } from "../assets/images/icons/ic-logout.svg";
import { ReactComponent as SkillsIcon } from "../assets/images/icons/ic-skills.svg";
import { ReactComponent as StudentClockIcon } from "../assets/images/icons/ic-student-clock.svg";
import { ReactComponent as StudentIcon } from "../assets/images/icons/ic-student.svg";
import { ReactComponent as StudentsIcon } from "../assets/images/icons/ic-students.svg";
import { ReactComponent as TestIcon } from "../assets/images/icons/ic-test-active.svg";
import { ReactComponent as VideoLessonIcon } from "../assets/images/icons/ic-video-lesson.svg";
import UserPlaceholder from "../assets/images/placeholders/user-placeholder.png";
import { Each } from "../common/Each";
import MainContext from "../common/MainContext";
import Button from "../components/Button";
import StatisticsCard from "../components/cards/StatisticCard";
import CircularProgress from "../components/CircularProgress";
import EditProfile from "../components/EditProfile";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import Tag from "../components/Tag";
import { auth } from "../firebase";
import { secondsToHours } from "../utils";
import styles from "./Profile.module.css";
import Skeleton from "../components/Skeleton";

const Profile = ({ }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const context = useContext(MainContext)
  const [showEditPage, setShowEditPage] = useState(false)
  const [tags, setTags] = useState([])
  const [circularContent, setCircularContent] = useState({
    label: "0%"
  })
  const [circularData, setCircularData] = useState([])
  const [lessons, setLessons] = useState({
    n_lessons: 0,
    n_scheduled: 0,
    n_completed: 0,
    n_postponed: 0,
    duration: 0,
    n_current_month: 0,
    n_previous_month: 0
  })
  const [users, setUsers] = useState({ n_users: 0, n_users_in_progress: 0 })
  const [exercises, setExercises] = useState({
    n_exercises: 0,
    n_previous_month: 0,
    n_current_month: 0,
    n_corrections: 0,
    n_corrections_previous_month: 0,
    n_corrections_current_month: 0
  })
  const [tests, setTests] = useState({ n_tests: 0, n_previous_month: 0, n_current_month: 0 })
  const [materials, setMaterials] = useState({ n_materials: 0, n_previous_month: 0, n_current_month: 0 })

  useEffect(() => {
    const getStatistics = async (route, setState) => {
      try {
        const response = await api.get(route)
        setState(response)
      } catch (e) {
        console.error(e)
      }
    }

    const getTags = async () => {
      try {
        const res = await api.get("/teacher/tags")
        setTags(res)
      } catch (e) {
        console.error(e)
      }
    }

    getStatistics("/teacher/lessons/statistics", setLessons)
    getStatistics("/teacher/users/statistics", setUsers)
    getStatistics("/teacher/exercises/statistics", setExercises)
    getStatistics("/teacher/tests/statistics", setTests)
    getStatistics("/teacher/materials/statistics", setMaterials)

    getTags()
  }, [])

  useEffect(() => {

    if (lessons.n_lessons === 0) {
      return;
    }

    setCircularContent({
      label: `${Math.round(lessons.n_completed / lessons.n_lessons * 10000) / 100}%`,
      sublabel: "Completamento"
    })

    setCircularData([
      {
        id: "total",
        value: null,
        rawValue: lessons.n_lessons,
        color: 'var(--tertiary)',
        label: `${t("profile.statistics.lessons")} ${t("profile.statistics.total")}`
      },
      {
        id: "completed",
        value: Math.round(lessons.n_completed / lessons.n_lessons * 10000) / 100,
        rawValue: lessons.n_completed,
        color: 'var(--sf-green)',
        label: `${t("profile.statistics.lessons")} ${t("profile.statistics.completed")}`
      },
      {
        id: "scheduled",
        value: Math.round(lessons.n_scheduled / lessons.n_lessons * 10000) / 100,
        rawValue: lessons.n_scheduled,
        color: 'var(--sf-yellow)',
        label: `${t("profile.statistics.lessons")} ${t("profile.statistics.scheduled")}`
      },
      {
        id: "postponed",
        value: Math.round(lessons.n_postponed / lessons.n_lessons * 10000) / 100,
        rawValue: lessons.n_postponed,
        color: '#A1AFBF',
        label: `${t("profile.statistics.lessons")} ${t("profile.statistics.postponed")}`
      },
    ])
  }, [lessons])

  useEffect(() => {
    window.scrollTo({
      top: 0,
    })
  }, [showEditPage])

  const logout = useCallback(async () => {
    await auth.signOut()
    context.setUser(null)
    navigate("/")
  }, [])

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>
            Profilo
          </title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          {
            showEditPage && <EditProfile onBack={() => setShowEditPage(false)} tagsList={tags} />
          }

          {
            !showEditPage &&
            <div className={styles.sectionInner}>
              <div className={styles.profileContainer}>
                <div className={styles.profile}>
                  <div className={styles.teacher}>
                    {
                      context.user?.picture &&
                      <img className={styles.profilePic} src={context.user?.picture} alt='' />
                    }
                    {
                      !context.user &&
                      <img className={styles.profilePic} src={UserPlaceholder} alt='' />
                    }
                    {
                      !context.user &&
                      <Skeleton type="rect" width="164px" height="24px" borderRadius={"12px"} />
                    }
                    {
                      context.user &&
                      <div className={styles.username}>{`${context.user?.name} ${context.user?.surname}`}</div>
                    }
                    <Button
                      disabled={!context.user}
                      onClick={() => {
                        setShowEditPage(true)
                      }}
                    >
                      <EditIcon />
                      {t("profile.edit").toUpperCase()}
                    </Button>
                  </div>
                  <div className={styles.teacherInfo}>
                    <div className={styles.attribute}>
                      <div className={styles.label}>
                        <EmailIcon className={styles.profileIcon} />
                        {t("profile.email")}
                      </div>
                      <div className={styles.attributeValue}>{context.user?.email}</div>
                    </div>
                    <div className={styles.attribute}>
                      <div className={styles.label}>
                        <QualificationIcon className={styles.profileIcon} />
                        {t("profile.qualification")}
                      </div>
                      <div className={styles.attributeValue}>{context.user?.qualification}</div>
                    </div>
                    <div className={styles.attribute}>
                      <div className={styles.label}>
                        <BioIcon className={styles.profileIcon} />
                        {t("profile.bio")}
                      </div>
                      <div className={styles.attributeValueFullWidth}>{context.user?.description}</div>
                    </div>
                    <div className={styles.attribute}>
                      <div className={styles.label}>
                        <SkillsIcon className={styles.profileIcon} />
                        {t("profile.skills")}
                      </div>
                      <div className={styles.attributeValueFullWidth}>
                        <Each
                          of={context.user?.tags ?? []}
                          render={(tag) => (
                            <Tag tag={tag} />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.logout}
                    onClick={logout}
                  >
                    <LogoutIcon />
                    Logout
                  </div>
                </div>
              </div>
              <div className={styles.statsContainer}>
                <div className={styles.card}>
                  <div className={styles.header}>
                    <div className={styles.subtitle}>{t("profile.statistics.title").toUpperCase()}</div>
                    <div className={styles.title}>{t("profile.statistics.lessons")}</div>
                  </div>
                  <div className={styles.pieChartContainer}>
                    <div className={styles.chartBadges}>
                      <Each
                        of={circularData}
                        render={(d) => (
                          <div className={styles.chartBadge}>
                            <div className={styles.left}>
                              <div className={styles.chartColor} style={{ backgroundColor: d.color }} />
                              <div className={styles.chartLabel} style={{ color: d.color }}>{d.label}</div>
                            </div>
                            <div className={styles.chartValue}>{d.rawValue}</div>
                          </div>
                        )}
                      />
                    </div>
                    <div className={styles.pieChart}>
                      <CircularProgress content={circularContent} data={circularData.filter(d => d.id !== "total")} />
                    </div>
                  </div>
                  <div className={styles.innerCards}>
                    <StatisticsCard
                      Icon={VideoLessonIcon}
                      color="var(--sf-blue)"
                      label="Lezioni in questo mese"
                      value={lessons.n_current_month}
                      increment={lessons.n_current_month - lessons.n_previous_month}
                    />
                    <StatisticsCard
                      Icon={HourglassIcon}
                      color="var(--tertiary)"
                      label="Durata totale lezioni"
                      value={secondsToHours(lessons.duration)}
                    />
                  </div>
                </div>
                <div className={styles.card}>
                  <div className={styles.header}>
                    <div className={styles.subtitle}>{t("profile.statistics.title").toUpperCase()}</div>
                    <div className={styles.title}>{t("profile.statistics.students")}</div>
                  </div>
                  <div className={styles.innerCards}>
                    <StatisticsCard
                      style={{ minWidth: "196px" }}
                      Icon={StudentsIcon}
                      color="var(--sf-purple)"
                      label={t("profile.statistics.totalStudents")}
                      value={users.n_users}
                    />
                    <StatisticsCard
                      style={{ minWidth: "196px" }}
                      Icon={StudentIcon}
                      color="var(--sf-fucsia)"
                      label={t("profile.statistics.currentStudents")}
                      value={users.n_users_in_progress}
                    />
                    <StatisticsCard
                      style={{ minWidth: "196px" }}
                      Icon={StudentClockIcon}
                      color="var(--sf-pink)"
                      label={t("profile.statistics.averageSession")}
                      value={secondsToHours(16858, true)}
                    />
                  </div>
                </div>
                <div className={styles.outerCards}>
                  <StatisticsCard
                    Icon={ExerciseIcon}
                    color="var(--sf-yellow)"
                    label={t("profile.statistics.requestedExercise")}
                    value={exercises.n_exercises}
                    increment={exercises.n_current_month - exercises.n_previous_month}
                  />
                  <StatisticsCard
                    Icon={TestIcon}
                    color="var(--tertiary)"
                    label={t("profile.statistics.createdTests")}
                    value={tests.n_tests}
                    increment={tests.n_current_month - tests.n_previous_month}
                  />
                  <StatisticsCard
                    Icon={CorrectionsIcon}
                    color="var(--sf-aqua)"
                    label={t("profile.statistics.corrections")}
                    value={exercises.n_corrections}
                    increment={exercises.n_corrections_current_month - exercises.n_corrections_previous_month}
                  />
                  <StatisticsCard
                    Icon={MaterialsIcon}
                    color="var(--sf-green)"
                    label={t("profile.statistics.materials")}
                    value={materials.n_materials}
                    increment={materials.n_current_month - materials.n_previous_month}
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </div>

    </HeaderFooterLayout>

  )
}


export default Profile

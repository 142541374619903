import styles from "./EmptyContent.module.css"

const EmptyContent = ({ title = "", message = "", Illustration = null, style = {}, imageSrc = null, imageStyle = {} }) => {

  return (
    <div className={styles.container}
      style={{ ...style }}
    >
      {
        Illustration &&
        <div className={styles.illustration} style={{ ...imageStyle }}>
          <Illustration />
        </div>
      }
      {
        imageSrc &&
        <div className={styles.illustration}>
          <img src={imageSrc} alt="" style={{ ...imageStyle }} />
        </div>
      }
      <div className={styles.title}>{title}</div>
      {
        message &&
        <div className={styles.message}>{message}</div>
      }
    </div>
  )
}

export default EmptyContent

import { useMemo, useState } from "react"
import styles from "./IconButton.module.css"

const IconButton = ({ IconComponent = <></>, text = "", value = "", color, backgroundColor, selected = false, onClick = () => { }, enableHover = false }) => {

  const [isHover, setIsHover] = useState(false)

  const style = useMemo(() => {
    if (isHover || selected) {
      return {
        color: "#FFFFFF",
        backgroundColor: color
      }
    }
    return {
      color,
      backgroundColor: backgroundColor
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHover, selected])

  return (
    <div
      onClick={onClick}
      className={styles.container}
      onMouseEnter={() => setIsHover(true && enableHover)}
      onMouseLeave={() => setIsHover(false)}
      style={{ ...style }}>
      <IconComponent className={styles.icon} />
    </div>
  )
}

export default IconButton

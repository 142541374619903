import { useTranslation } from "react-i18next"
import { ReactComponent as AlertIcon } from "../../assets/images/icons/ic-attention.svg"
import { ReactComponent as ErrorIcon } from "../../assets/images/icons/ic-close.svg"
import { Each } from "../../common/Each"
import { DialogStatus } from "../../enums"
import Succeeded from "../animated/Succeeded"
import Button from "../Button"
import Loader from "../Loader"
import styles from "./AlertDialog.module.css"
import Dialog from "./Dialog"

const AlertDialog = ({
  open = false,
  dialogProps = {},
  title = "",
  text = "",
  IconComponent = AlertIcon,
  actions = [],
  onClose = () => { },
  status = DialogStatus.Default,
  successMessage = "",
  errorMessage = ""
}) => {

  const { t } = useTranslation()

  return (
    <Dialog open={open} {...dialogProps}
      onClose={onClose}
      noHeader
      style={{
        maxWidth: "512px"
      }}
    >
      <div className={styles.container}>
        {status === DialogStatus.Loading && <div className={styles.icon}><Loader /></div>}
        {status === DialogStatus.Default && <IconComponent className={styles.icon} />}
        {status === DialogStatus.Success &&
          <div className={styles.icon}>
            <Succeeded width="100%" height="100%" />
          </div>
        }
        {status === DialogStatus.Error &&
          <>
            <ErrorIcon className={styles.icon} style={{ color: "var(--secondary)" }} />
            <div className={styles.title}>{errorMessage || t("dialog.defaultError")}</div>
          </>
        }
        <div className={styles.title}>{title}</div>
        {text && <div className={styles.text}>{text}</div>}
        <div className={styles.actions}>
          <Each
            of={actions}
            render={(action) => (
              <Button
                style={{
                  padding: ".8rem 2rem",
                  marginTop: "1rem"
                }}
                onClick={action.onClick ?? (() => { })}
                disabled={status !== DialogStatus.Default}>
                {action.label}
              </Button>
            )}
          />
        </div>
      </div>
    </Dialog>
  )
}


export default AlertDialog

import { formatDateV2, formatFileSize, formatTimeV2 } from "../../utils"
import Card from "./Card"
import styles from "./MaterialCard.module.css"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import { ReactComponent as LessonIcon } from "../../assets/images/icons/ic-lessons-inactive.svg"
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-edit.svg"
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/ic-delete.svg"
import { ReactComponent as ThumbnailPdf } from "../../assets/images/illustrations/il-thumbnail-pdf.svg"
import { ReactComponent as ThumbnailPpt } from "../../assets/images/illustrations/il-thumbnail-ppt.svg"
import { ReactComponent as ThumbnailVideo } from "../../assets/images/illustrations/il-thumbnail-video.svg"
import { ReactComponent as ThumbnailExcel } from "../../assets/images/illustrations/il-thumbnail-excel.svg"
import { ReactComponent as ThumbnailText } from "../../assets/images/illustrations/il-thumbnail-text.svg"
import { ReactComponent as ThumbnailGeneric } from "../../assets/images/illustrations/il-thumbnail-generic.svg"
import { ReactComponent as ThumbnailImage } from "../../assets/images/illustrations/il-thumbnail-image.svg"
import Skeleton from "../Skeleton"
import MeatBallsMenu from "../MeatBallsMenu"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import { useCallback, useEffect, useMemo, useState } from "react"
import api from "../../api"
import AlertDialog from "../dialogs/AlertDialog"
import { useTranslation } from "react-i18next"
import { DialogStatus } from "../../enums"
import Dialog from "../dialogs/Dialog"
import Button from "../Button"
import DropdownSelection from "../DropdownSelection"
import Loader from "../Loader"

const supportedImageExtensions = ["jpg", "jpeg", "png", "svg", "webp", "gif", "avif", "apng"]
const supportedVideoExtensions = ["mp4", "m4p", "mov", "flv", "mkv", "avi", "wmv", "mpg", "mpeg", "m2v"]

const imagesExtensions = ["jpg", "jpeg", "png", "svg", "webp", "gif", "avif", "apng"]
const videoExtensions = ["mp4", "m4p", "mov", "flv", "mkv", "avi", "wmv", "mpg", "mpeg", "m2v"]
const pptExtensions = ["ppt", "pptx"]
const excelExtensions = ["xlsx", "xls"]
const textExtensions = ["docx", "doc"]

const MaterialCard = ({ material, loading = false, onDelete = () => { }, onEdit = () => { }, modules = [], loadingModules = false }) => {
    const { width } = useWindowDimensions()
    const { t } = useTranslation()

    const [type, setType] = useState(null)
    const [openAlert, setOpenAlert] = useState(false)
    const [alertStatus, setAlertStatus] = useState(DialogStatus.Default)
    const [closeTimeout, setCloseTimeout] = useState(null)
    const [closeEditTimeout, setCloseEditTimeout] = useState(null)

    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [lessons, setLessons] = useState([])
    const [selectedModule, setSelectedModule] = useState(null)
    const [selectedLesson, setSelectedLesson] = useState(null)
    const [disabledEdit, setDisabledEdit] = useState(false)
    const [editStatus, setEditStatus] = useState(DialogStatus.Default)

    const Thumbnail = useMemo(() => {
        if (material) {
            const split = material.name.split(".")
            const fileExtension = split.length > 1 ? split[split.length - 1].toLowerCase() : ""
            if (videoExtensions.includes(fileExtension)) {
                return ThumbnailVideo
            }
            if (imagesExtensions.includes(fileExtension)) {
                return ThumbnailImage
            }
            if (pptExtensions.includes(fileExtension)) {
                return ThumbnailPpt
            }
            if (pptExtensions.includes(fileExtension)) {
                return ThumbnailPpt
            }
            if (excelExtensions.includes(fileExtension)) {
                return ThumbnailExcel
            }
            if (textExtensions.includes(fileExtension)) {
                return ThumbnailText
            }
            if (fileExtension === "pdf") {
                return ThumbnailPdf
            }
        }
        return ThumbnailGeneric
    }, [material])

    useEffect(() => {
        if (material) {
            const fileExtension = material.url.split(".").slice(-1)[0].toLowerCase()
            if (supportedImageExtensions.includes(fileExtension)) {
                setType("image")
            } else if (supportedVideoExtensions.includes(fileExtension)) {
                setType("video")
            } else if (fileExtension === "pdf") {
                setType("pdf")
            } else {
                setType(null)
            }
        }
    }, [material])

    useEffect(() => {
        return () => {
            if (closeTimeout) {
                clearTimeout(closeTimeout)
            }
            if (closeEditTimeout) {
                clearTimeout(closeEditTimeout)
            }
        }
    }, [])

    useEffect(() => {
        if (!openEditDialog) {
            setSelectedModule(null)
            setSelectedLesson(null)
            setLessons([])
            setEditStatus(DialogStatus.Default)
            setAlertStatus(DialogStatus.Default)
        } else {
            const { module, lesson } = material;
            const moduleIdx = modules.findIndex((m) => m.id === module.id)
            if (moduleIdx >= 0) {
                setSelectedModule(moduleIdx)

                const { lessons } = modules[moduleIdx]
                const l = lessons.map((l) => {
                    return {
                        ...l,
                        label: l.name
                    }
                })
                l.push({ label: "Nessuna lezione", id: null })
                setLessons(l)

                if (lesson) {
                    const lessonIdx = lessons.findIndex((l) => l.id === lesson.id)
                    if (lessonIdx >= 0) {
                        setSelectedLesson(lessonIdx)
                    }
                }
            }
        }
    }, [openEditDialog])

    useEffect(() => {
        if (!openAlert) setAlertStatus(DialogStatus.Default)
    }, [openAlert])

    useEffect(() => {
        if (selectedModule >= 0 && modules[selectedModule] && selectedLesson === null) {
            const { lessons } = modules[selectedModule]
            const l = lessons.map((l) => {
                return {
                    ...l,
                    label: l.name
                }
            })
            l.push({ label: "Nessuna lezione", id: null })
            setLessons(l)
        }
    }, [selectedModule, selectedLesson])

    const openMaterial = useCallback(() => {
        const newWindow = window.open("", "_blank")
        let content = ""
        switch (type) {
            case "image":
                content = `<img src="${material.url}" alt=""/>`
                break
            case "video":
                content = `
                <video width="320" height="240" controls>
                    <source src="${material.url}">
                </video>`
                break
            case "pdf":
                content = `<embed src="${material.url}" width="500" height="375" type="application/pdf">`
                break
            default: break
        }

        newWindow.document.write(`
            <html>
                <head>
                </head>
                <body>
                ${content}
                </body>
            </html>
        `);



    }, [material, type])

    const editMaterial = useCallback(async () => {
        setEditStatus(DialogStatus.Loading)
        try {
            const body = {}
            body.module_id = modules[selectedModule].id
            if (selectedLesson !== null) {
                body.lesson_id = lessons[selectedLesson].id
            }
            const newMaterial = await api.put(`/teacher/materials/${material.id}`, body)

            newMaterial.module = modules[selectedModule]
            if (selectedLesson !== null) {
                newMaterial.lesson = lessons[selectedLesson]
            }
            setEditStatus(DialogStatus.Success)
            onEdit(newMaterial)
            setCloseEditTimeout(setTimeout(() => setOpenEditDialog(false), 3000))

        } catch (e) {
            console.error(e)
            setEditStatus(DialogStatus.Error)
            setCloseEditTimeout(setTimeout(() => setOpenEditDialog(false), 3000))
        }
    }, [material, selectedModule, selectedLesson, modules, lessons, onEdit])

    const deleteMaterial = useCallback(async () => {
        try {
            setAlertStatus(DialogStatus.Loading)
            await api.delete(`/teacher/materials/${material.id}`)
            onDelete(material.id)
            setAlertStatus(DialogStatus.Success)

            setCloseTimeout(setTimeout(() => setOpenAlert(false), 3000))
        } catch (e) {
            console.error(e)
            setAlertStatus(DialogStatus.Error)

            setCloseTimeout(setTimeout(() => {
                setOpenAlert(false)
            }, 3000))
        }
    }, [material, onDelete])

    return (
        <>
            {
                loading &&
                <div className={styles.container}>
                    <Skeleton type="rect" width={"100px"} height={"66px"} borderRadius={"6px"} />
                    <div className={styles.material}>
                        <Skeleton type="rect" width={"256px"} height={"21px"} borderRadius={"4px"} />
                        <Skeleton type="rect" width={"96px"} height={"21px"} borderRadius={"4px"} />
                    </div>
                    <div className={styles.lessonName}>
                        <Skeleton type="rect" width={"96px"} height={"21px"} borderRadius={"4px"} />
                    </div>
                    <div className={styles.time}>
                        <Skeleton type="rect" width={"96px"} height={"21px"} borderRadius={"4px"} />
                    </div>
                    <div className={styles.menu}>
                        <MeatBallsMenu disabled={true} />
                    </div>
                </div>
            }
            {
                loading === false &&
                <div className={styles.container}>
                    {/* {
                        !material?.thumbnail && */}
                    <Thumbnail className={styles.thumbnail} onClick={openMaterial} />
                    {/* } */}
                    {/* {
                        material?.thumbnail &&
                        <img src={material.thumbnail} className={styles.thumbnail} alt=""
                            style={{ cursor: type !== null ? "pointer" : "inherit" }}
                            onClick={openMaterial}
                        />
                    } */}

                    <div className={styles.material}>
                        <div className={styles.name}>{material.name}</div>
                        <div className={styles.size}>{formatFileSize(material.size)}</div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.lessonName}>
                            {material.lesson && material.lesson.name &&
                                <>
                                    <LessonIcon className={styles.icon} />
                                    {material.lesson.name}
                                </>
                            }
                        </div>
                        <div className={styles.time}>
                            {formatTimeV2(material.created_at)}, {formatDateV2(material.created_at)} <ClockIcon className={styles.icon} />
                        </div>
                    </div>
                    <div className={styles.menu}>
                        <MeatBallsMenu
                            disabled={loadingModules}
                            actions={[
                                {
                                    label: t("edit"), icon: EditIcon, style: { color: "var(--primary)" }, onClick: () => setOpenEditDialog(true)
                                },
                                {
                                    label: t("delete"), icon: DeleteIcon, style: { color: "var(--secondary)" }, onClick: () => setOpenAlert(true)
                                }
                            ]} />
                    </div>
                </div>
            }
            <AlertDialog
                open={openAlert}
                title={t("materials.deleteTitle")}
                text={t("materials.deleteText")}
                status={alertStatus}
                onClose={() => {
                    setOpenAlert(false)
                    if (closeTimeout) {
                        clearTimeout(closeTimeout)
                    }
                }}
                actions={[
                    {
                        label: t("materials.deleteConfirm").toUpperCase(),
                        onClick: deleteMaterial,
                    }
                ]}
            />

            <Dialog
                open={openEditDialog}
                title={t("materials.editTitle")}
                style={{ maxWidth: "512px" }}
                status={editStatus}
                onClose={() => {
                    setOpenEditDialog(false)
                    if (closeEditTimeout) {
                        clearTimeout(closeEditTimeout)
                    }
                }}
                action={
                    <Button onClick={editMaterial} disabled={disabledEdit || editStatus !== DialogStatus.Default}>
                        {t("materials.save").toUpperCase()}
                    </Button>
                }
            >
                <div className={styles.dialog}>

                    <DropdownSelection
                        disabled={modules.length === 0}
                        placeholder={"Associa un modulo"}
                        defaultOptionIndex={selectedModule}
                        options={modules}
                        appereance="transparent"
                        onSelect={(index) => {
                            if (index !== selectedModule) {
                                setSelectedModule(index)
                                setSelectedLesson(null)
                            }
                        }} />

                    <DropdownSelection
                        disabled={lessons.length === 0}
                        placeholder={"Associa una lezione"}
                        defaultOptionIndex={selectedLesson}
                        options={lessons}
                        appereance="transparent"
                        onSelect={(index) => setSelectedLesson(index)} />

                </div>
            </Dialog>
        </>

    )
}

export default MaterialCard

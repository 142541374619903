import { motion } from "framer-motion"

const Succeeded = ({ width = "200px", height = "200px" }) => {

    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <motion.path
                initial={{ pathLength: 0, opacity: 0 }}
                animate={{ pathLength: 1, opacity: 1 }}
                transition={{ duration: .2, ease: 'easeInOut', delay: .8 }}
                d="M8.5 12.5L10.5 14.5L15.5 9.5"
                stroke="#2fc6a0"
                strokeWidth=".5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <motion.path
                initial={{ pathLength: 0, opacity: 0 }}
                animate={{ pathLength: 1, opacity: 1 }}
                transition={{ duration: .8, ease: 'easeInOut' }}
                d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
                stroke="#2fc6a0"
                strokeWidth=".5"
                strokeLinecap="round" />
        </svg>
    )
}

export default Succeeded
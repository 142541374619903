import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as CheckIcon } from "../../assets/images/icons/ic-check.svg"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/ic-delete.svg"
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-edit.svg"
import { ReactComponent as TestIcon } from "../../assets/images/icons/ic-test-add.svg"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import { DialogStatus, TestStatus } from "../../enums"
import { calcLastUpdate, capitalize, formatDateV2, formatTimeV2 } from "../../utils"
import MeatBallsMenu from "../MeatBallsMenu"
import Card from "./Card"
import TestCard from "./TestCard"
import styles from "./TestFeedCard.module.css"
import api from "../../api"
import AlertDialog from "../dialogs/AlertDialog"

const TestFeedCard = ({ activity, onDelete = () => { }, onComplete = () => { } }) => {
  const { width } = useWindowDimensions()
  const { t } = useTranslation()

  // delete
  const [openAlert, setOpenAlert] = useState(false)
  const [alertStatus, setAlertStatus] = useState(DialogStatus.Default)
  const [closeTimeout, setCloseTimeout] = useState(null)

  // complete
  const [openCompleteAlert, setOpenCompleteAlert] = useState(false)
  const [completeAlertStatus, setCompleteAlertStatus] = useState(DialogStatus.Default)
  const [completeCloseTimeout, setCompleteCloseTimeout] = useState(null)

  const menuActions = useMemo(() => {
    if (!activity) return []
    const test = activity.object
    const actions = [
      {
        label: t("tests.delete"), icon: DeleteIcon, style: { color: "var(--secondary)" }, onClick: () => setOpenAlert(true)
      }
    ]

    if (test.status === TestStatus.Public) {
      actions.unshift({
        label: t("tests.end"), icon: CheckIcon, style: { color: "var(--primary)" }, onClick: () => setOpenCompleteAlert(true)
      })
    }
    return actions
  }, [activity])

  const deleteTest = useCallback(async () => {
    try {
      setAlertStatus(DialogStatus.Loading)
      await api.delete(`/teacher/tests/${activity.object_id}`)
      onDelete({ activity_id: activity.id, deleted: true })
      setAlertStatus(DialogStatus.Success)
      setCloseTimeout(setTimeout(() => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
      }, 3000))
    } catch (e) {
      console.error(e)
      setAlertStatus(DialogStatus.Error)

      setCloseTimeout(setTimeout(() => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
      }, 3000))
    }
  }, [activity, onDelete])

  const completeTest = useCallback(async () => {
    try {
      setCompleteAlertStatus(DialogStatus.Loading)
      await api.put(`/teacher/tests/${activity.object_id}`, { status: TestStatus.Completed })
      onComplete({ activity_id: activity.id })
      setCompleteAlertStatus(DialogStatus.Success)
      setCompleteCloseTimeout(setTimeout(() => {
        setOpenCompleteAlert(false)
        setCompleteAlertStatus(DialogStatus.Default)
      }, 3000))
    } catch (e) {
      console.error(e)
      setCompleteAlertStatus(DialogStatus.Error)

      setCompleteCloseTimeout(setTimeout(() => {
        setOpenCompleteAlert(false)
        setCompleteAlertStatus(DialogStatus.Default)
      }, 3000))
    }
  }, [activity, onComplete])

  return (
    <Card style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
      <div className={styles.cardHeader}>
        <div className={`${styles.icon} ${styles.test}`}>
          <TestIcon />
        </div>
        <div className={styles.info}>
          <div className={styles.title}>
            {t("tests.title")}
          </div>
          <div className={styles.subtitle}>
            {
              activity.object?.expires_at &&
              <>
                {width > 540 &&
                  <>
                    Entro le {formatTimeV2(activity.object.expires_at)} di {capitalize(formatDateV2(activity.object.expires_at, { weekday: 'long', day: 'numeric', month: 'long' }))}
                  </>
                }
                {width < 540 &&
                  <>
                    Entro {formatDateV2(activity.object.expires_at, { weekday: 'short', day: 'numeric', month: 'short' }).toUpperCase()}, {formatTimeV2(activity.object.expires_at)}
                  </>
                }
              </>
            }
          </div>
        </div>
        <div className={styles.date}>
          {calcLastUpdate(activity.created_at, width > 768 ? false : true)}
          <ClockIcon className={styles.clockIcon} />
        </div>
        <MeatBallsMenu actions={menuActions} />
      </div>
      <div className={styles.content} style={{ padding: '1rem' }}>
        {
          activity.info?.message &&
          <div className={styles.message}>{activity.info.message}</div>
        }
        <div>
          <TestCard test={activity.object} useVariant hideMenu />
        </div>
      </div>
      <AlertDialog
        open={openAlert}
        title={t("tests.deleteTitle")}
        text={t("tests.deleteText")}
        status={alertStatus}
        onClose={() => {
          setOpenAlert(false)
          setAlertStatus(DialogStatus.Default)
          if (closeTimeout) {
            clearTimeout(closeTimeout)
          }
        }}
        actions={[
          {
            label: t("tests.deleteConfirm").toUpperCase(),
            onClick: deleteTest,
          }
        ]}
      />

      <AlertDialog
        open={openCompleteAlert}
        title={t("tests.completeTitle")}
        text={t("tests.completeText")}
        status={completeAlertStatus}
        onClose={() => {
          setOpenCompleteAlert(false)
          setCompleteAlertStatus(DialogStatus.Default)
          if (completeCloseTimeout) {
            clearTimeout(completeCloseTimeout)
          }
        }}
        actions={[
          {
            label: t("tests.completeConfirm").toUpperCase(),
            onClick: completeTest,
          }
        ]}
      />
    </Card>
  )
}

export default TestFeedCard

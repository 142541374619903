import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { ReactComponent as BookIcon } from "../../assets/images/icons/ic-book-active.svg"
import { CourseStatus, CourseType } from "../../common/constants"
import { Each } from "../../common/Each"
import typo from "../../typography.module.css"
import Button from "../Button"
import CourseBadge from "../CourseBadge"
import styles from "./TeacherCourseCardSmall.module.css"

const TeacherCourseCardSmall = ({ edition }) => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={edition.course.type === CourseType.Masterclass ? styles.noWrapper : styles.wrapper}>
      {edition.course.type === CourseType.Professional &&
        <div className={typo.subheadline}>{edition.course.name} - {edition.name}</div>
      }
      <Each
        of={edition.modules}
        render={(module, index) => (
          <div className={styles.container}>
            <div className={styles.thumbnail}>
              <img src={edition.course.thumbnail} alt="course-thumbnail"></img>
              <CourseBadge type={edition.course.type} style={{ position: "absolute", top: ".5rem", left: ".5rem" }} />
              <div className={styles.metas}>
                {edition.course.type === CourseType.Professional &&
                  <div className={styles.meta}>
                    {/* <BookIcon></BookIcon> */}
                    {/* <div>{edition.course.program.length} moduli</div> */}
                  </div>
                }
              </div>
            </div>
            <div className={styles.info}>
              {
                edition.course.type === CourseType.Professional &&
                <div className={typo.subheadline}>{module.name}</div>
              }
              {
                edition.course.type === CourseType.Masterclass &&
                <div className={typo.subheadline}>{edition.course.name} - {edition.name}</div>
              }
              <div className={`${typo.body} ${styles.description}`} style={{ opacity: 0.4 }}>{module.description}</div>
            </div>
            <div className={styles.spacer}></div>
            <div className={styles.divider}></div>
            <div className={styles.spacer}></div>
            <div className={styles.purchase}>
              <Button inverse={edition.course.status !== CourseStatus.ToStart} style={{ width: '100%' }} onClick={() => {
                navigate(`/courses/${module.id}`)
              }}>
                {t(edition.course.status === CourseStatus.ToStart ? 'courses.subscribe' : 'courses.enter').toUpperCase()}
              </Button>
            </div>
          </div>
        )}
      />
    </div>

  )
}

export default TeacherCourseCardSmall

import { useEffect, useState } from "react"
import Button from "../Button"
import Dialog from "./Dialog"
import styles from "./NotificationPermissionDialog.module.css"
import { isSupported } from "firebase/messaging"

const NotificationPermissionDialog = ({ open, onClose = () => { } }) => {

    const [isOpen, setIsOpen] = useState(open)

    useEffect(() => {
        setIsOpen(open)
    }, [open])


    const getNotificationsToken = async () => {
        try {
            const supported = await isSupported()
            if (supported) {
                await Notification.requestPermission()
            }
            handleClose()
        }
        catch (e) {
            console.error(e)
        }
    }

    const handleClose = () => {
        setIsOpen(false)
        onClose()
    }

    return (
        <Dialog title="Notifiche Push" open={isOpen} style={{paddingBottom: '1rem'}} onClose={handleClose}>
            <div className={styles.message}>
                Sembra che tu non abbia attivato le notifiche push.<br></br> Attivale ora per ricevere aggiornamenti in tempo reale riguardo i corsi, le lezioni e i messaggi.
                <br></br><br></br>Le notifiche <strong>NON</strong> saranno utilizzate per scopi di marketing o pubblicitari.
            </div>
            <Button
                onClick={() => {
                    getNotificationsToken()
                }}
            >ATTIVA</Button>
        </Dialog>
    )
}

export default NotificationPermissionDialog
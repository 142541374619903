import styles from "./Hamburger.module.css"

const Hamburger = ({ isOpen, onChange }) => {


    const handleOnChange = async (event) => {
        if (onChange && typeof onChange === 'function') {
            onChange(event)
        }

    }

    return (
        <label className={styles.label}>
            <input className={styles.input} type="checkbox" checked={isOpen ?? false} onChange={handleOnChange} />
            <span></span>
            <span></span>
            <span></span>
        </label>
    )
}

export default Hamburger

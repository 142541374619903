import style from "./HeaderFooterLayout.module.css"
import Header from "./Header"
import Footer from "./Footer"
import { useContext, useEffect, useState } from "react"
import MainContext from "../../common/MainContext"
import TopNav from "./TopNav"

const HeaderFooterLayout = ({ children, hideFooter = false, hideHeader = false }) => {

    const context = useContext(MainContext)
    const [showHeader, setShowHeader] = useState(false)

    useEffect(() => {
        if (context.user) {
            setShowHeader(true)
        }
    }, [context.user])

    return (
        <div className={style.container}>
            {showHeader && !hideHeader &&
                <Header></Header>
            }
            <div className={style.content}>
                {children}
            </div>
            {!hideFooter &&
                <Footer></Footer>
            }
        </div>
    )
}

export default HeaderFooterLayout
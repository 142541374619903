
import { useCallback, useEffect, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import api from "../api"
import { ReactComponent as EmptyIllustration } from "../assets/images/illustrations/il-empty.svg"
import { Each } from "../common/Each"
import Back from "../components/Back"
import Card from "../components/cards/Card"
import ModuleLessonCard from "../components/cards/ModuleLessonCard"
import EmptyContent from "../components/EmptyContent"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Skeleton from "../components/Skeleton"
import typo from "../typography.module.css"
import LessonEdit from "./LessonEdit"
import styles from "./Lessons.module.css"
import TextInput from "../components/TextInput"

const Lessons = () => {

  const { t } = useTranslation()

  const [lessonsPerModules, setLessonsPerModules] = useState([]);
  const [lessonsLoading, setLessonsLoading] = useState(false)
  const [lessons, setLessons] = useState([])
  const [filteredLessons, setFilteredLessons] = useState([])
  const [selectedLesson, setSelectedLesson] = useState(null)
  const [tags, setTags] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [selectedLesson])

  useEffect(() => {
    const lessonsPerModules = {}
    for (const lesson of filteredLessons) {
      if (lesson.module.id in lessonsPerModules) {
        lessonsPerModules[lesson.module.id].lessons.push(lesson)
      } else {
        lessonsPerModules[lesson.module.id] = {
          module_id: lesson.module.id,
          module_name: lesson.module.name,
          lessons: [lesson]
        }
      }
    }
    const flattedLessons = Object.values(lessonsPerModules)
    setLessonsPerModules(flattedLessons);
  }, [filteredLessons])

  useEffect(() => {
    const getLessons = async () => {
      setLessonsLoading(true);
      try {
        const lessons = await api.get("/teacher/lessons");
        setLessons(lessons)
        setFilteredLessons(lessons)
      } catch (e) {
        console.error(e);
      }
      setLessonsLoading(false);
    };

    const getTags = async () => {
      try {
        const res = await api.get("/teacher/tags");
        setTags(res);
      } catch (e) {
        console.error(e);
      }
    };

    getLessons();
    getTags()
  }, []);

  const onSave = useCallback((newLesson) => {
    setLessonsPerModules(lpm => {
      try {
        const mIdx = lpm.findIndex(m => m.module_id === newLesson.module.id)
        const idx = lpm[mIdx].lessons.findIndex(l => l.id === newLesson.id)
        lpm[mIdx].lessons[idx] = newLesson
      } catch (e) {
        console.error("lesson not found", e)
      }
      return [...lpm]
    })
    setSelectedLesson(null)
  }, [])

  const onSearch = (searchText) => {
    if (searchText) {
      console.debug(lessons)
      searchText = searchText.toLowerCase()
      const filtered = lessons.filter(l => (
        l.name.toLowerCase().trim().includes(searchText) ||
        l.module.name.toLowerCase().trim().includes(searchText) ||
        l.description.toLowerCase().trim().includes(searchText) ||
        (l.starts_at && l.starts_at.toLowerCase().trim().includes(searchText))
      ))
      setFilteredLessons(filtered)
    } else {
      setFilteredLessons(lessons)
    }
  }

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>Lezioni</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            {
              selectedLesson === null && lessonsLoading === false &&
              <>
                <div className={typo.title}>{t("lessons.title")}</div>
                <TextInput
                  onKeyUp={onSearch}
                  type="search"
                  placeholder={t("search")}
                />
                <Each
                  of={lessonsPerModules}
                  render={(module) => (
                    <Card>
                      <div className={styles.module}>
                        <div className={typo.subheadline}>{module.module_name}</div>
                        <div className={styles.lessons}>
                          <Each
                            of={module.lessons}
                            render={(lesson) => (
                              <ModuleLessonCard lesson={lesson} onEdit={() => {
                                setSelectedLesson(lesson)
                              }} />
                            )}
                          />
                        </div>
                      </div>
                    </Card>
                  )}
                />
              </>
            }

            {
              selectedLesson === null && lessonsLoading === false && lessonsPerModules.length === 0 &&
              <EmptyContent
                Illustration={EmptyIllustration}
                title={"Non ci sono ancora lezioni"}
                message={t("courses.noLessonsAdvice")}
              />
            }
            {
              selectedLesson === null && lessonsLoading === true &&
              <>
                <div className={typo.title}>{t("lessons.title")}</div>
                <Each
                  of={[0, 1]}
                  render={() => (
                    <Card>
                      <div className={styles.module}>
                        <div className={typo.subheadline}>
                          <Skeleton type="rect" width="256px" height={"22px"} borderRadius={"12px"} />
                        </div>
                        <div className={styles.lessons}>
                          <Each
                            of={[0]}
                            render={() => (
                              <ModuleLessonCard loading />
                            )}
                          />
                        </div>
                      </div>
                    </Card>
                  )}
                />
              </>
            }
            {
              selectedLesson &&
              <>
                <Back onClick={() => setSelectedLesson(null)} />
                <LessonEdit lesson={selectedLesson} tagsList={tags} onSave={onSave} />
              </>
            }
          </div>
        </div>
      </div>

    </HeaderFooterLayout>
  )
}

export default Lessons

import {
  BoldItalicUnderlineToggles,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  MDXEditor,
  Separator,
  toolbarPlugin,
  UndoRedo
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as AddIcon } from "../../assets/images/icons/ic-add.svg"
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg"
import { Each } from "../../common/Each"
import { getSingleWords, isTestTextCompletionValid, wrapWordsWithAsterisks } from "../../utils"
import DropdownMultiSelection from "../DropdownMultiSelection"
import Tag from "../Tag"
import Card from "../cards/Card"
import styles from "./TestTextCompletion.module.css"
import TagSelector from '../TagSelector'

const TestTextCompletion = ({
  tags = [],
  words = [{
    text: "",
    hidden: false,
  }],
  tagList = [],
  onChange = () => { },
}) => {
  const { t } = useTranslation()

  const [_words, setWords] = useState(words)
  const [hoverWord, setHoverWord] = useState(null)
  const [_text, setText] = useState(words.map(w => w.markdown).join(" "))
  const [filteredTagsList, setFilteredTagList] = useState(tagList)
  const [height, setHeight] = useState(null)
  const [_tags, setTags] = useState(tags)

  useEffect(() => {
    setFilteredTagList(tagList)
  }, [tagList])

  useEffect(() => {
    const ids = _tags.map(t => t.id)
    if (ids.length > 0) {
      setFilteredTagList(tagList.filter(t => ids.includes(t.id) === false))
    } else {
      setFilteredTagList(tagList)
    }
  }, [_tags])

  useEffect(() => {
    if (!_text) {
      setWords([])
      return;
    }

    const parsedText = wrapWordsWithAsterisks(_text)
    if (_words.length > 0) {
      const oldWords = [..._words]
      const newWords = getSingleWords(parsedText)

      let i = 0
      let j = 0
      while (i < newWords.length && j < oldWords.length) {
        if (oldWords[j].markdown === newWords[i].markdown) {
          // nothing changed here
          newWords[i] = oldWords[j]
        } else if (i > 0 && i < newWords.length - 1 &&
          j > 0 && j < oldWords.length - 1 &&
          newWords[i - 1].markdown === oldWords[j - 1].markdown &&
          newWords[i + 1].markdown === oldWords[j + 1].markdown
        ) {
          // previous and next word are always the same, so the current word changed but it still present
          newWords[i].hidden = oldWords[j].hidden
        } else if (i > 0 && i < newWords.length - 1 &&
          j > 0 && j < oldWords.length - 1 &&
          newWords[i].markdown === oldWords[j + 1].markdown) {
          // old Lorem i dolor 
          // new Lorem  dolor 
          // equal "dolor" => deleted word before "dolor"
          newWords[i].hidden = oldWords[j + 1].hidden
          j++
        } else if (i > 0 && i < newWords.length - 1 &&
          j > 0 && j < oldWords.length - 1 &&
          newWords[i].markdown === oldWords[j - 1].markdown) {
          // old Lorem i dolor 
          // new Lorem  dolor 
          // equal "dolor" => deleted word before "dolor"
          newWords[i].hidden = oldWords[j - 1].hidden
          j--
        }
        if (newWords[i].hidden) {
          newWords[i].solutions = [newWords[i].text]
        }
        i++
        j++
      }

      setWords(newWords)
    } else {
      const words = getSingleWords(parsedText)
      setWords(words)
    }
  }, [_text])

  useEffect(() => {
    const validation = isTestTextCompletionValid(_words)
    const { isValid, atLeastOneHiddenWord, emptyText } = validation

    let validationError = ""
    if (emptyText && !atLeastOneHiddenWord) {
      validationError = ""
    } else if (!atLeastOneHiddenWord) {
      validationError = t("tests.validation.noHiddenWords")
    } else if (emptyText) {
      validationError = t("tests.validation.emptyText")
    }
    const changes = {
      words: _words,
      tags: _tags,
      isValid,
      validationError,
    }
    onChange(changes)
  }, [_words, _tags])

  return (
    <div className={styles.container}>
      <TagSelector
        selected={_tags}
        options={filteredTagsList}
        placeholder={(_tags.length === 0 && t("tests.tagsPlaceholder")) || ""}
        onAdd={(item) => {
          console.debug(item)
          setTags(t => {
            t.push(item)
            return [...t]
          })
        }}
        onRemove={(item) => {
          setTags(t => {
            t = t.filter((tt) => tt.id !== item.id)
            return [...t]
          })
        }}
      />
      <Card style={{ padding: ".5rem 0rem", paddingBottom: "0", overflow: "hidden", fontSize: '1rem', backgroundColor: "var(--background-secondary-color)" }}>
        <div className={styles.cardContent}>
          <div className={styles.sectionTitle} style={{ paddingLeft: '1rem' }}>{t("tests.testType.textCompletion.helperText1")}</div>
          <MDXEditor
            className={styles.editor}
            markdown={_text}
            onChange={(value) => {
              setText(value)
            }}
            plugins={[
              diffSourcePlugin(),
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    {' '}
                    <UndoRedo />
                    <Separator />
                    <BoldItalicUnderlineToggles options={["Bold", "Italic"]} />
                    <Separator />
                    <DiffSourceToggleWrapper options={["rich-text", "source"]} />
                  </>
                )
              })
            ]}
          />
        </div>

      </Card>

      <Card hover style={{ padding: ".5rem 1rem", backgroundColor: "var(--background-secondary-color)" }} title={t("tests.testType.textCompletion.helperText2")} tag={t("preview")}>
        <div className={styles.words}>
          <Each
            of={_words}
            render={(word, index) => {
              const isSelected = word.hidden === true
              const className = isSelected ? `${styles.word} ${styles.selectedWord}` :
                hoverWord === index ? `${styles.word} ${styles.hoverWord}` : styles.word

              return (
                <div
                  className={className}
                  onMouseEnter={() => setHoverWord(index)}
                  onMouseLeave={() => setHoverWord(null)}
                  onClick={() => {
                    setWords((w) => {
                      w[index].hidden = !isSelected;
                      if (w[index].hidden) {
                        w[index].solutions = [w[index].text]
                      } else if (w[index].solutions) {
                        delete w[index].solutions
                      }
                      return [...w]
                    })
                  }}
                >
                  {word.text}
                  {isSelected && <CloseIcon className={styles.icon} />}
                  {!isSelected && hoverWord === index && <AddIcon className={styles.icon} style={{ color: "var(--primary)" }} />}
                </div>
              )
            }}
          />
        </div>
      </Card>
    </div>
  )
}

export default TestTextCompletion

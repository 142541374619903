export const ModulePages = {
  Students: "students",
  Lessons: "lessons",
  Materials: "materials",
  Exercises: "exercises",
  Tests: "tests",
  LessonEdit: "lessonEdit",
  TestEdit: "testEdit",
}

export const FeedContentType = {
  Communication: "communication",
  Material: "material",
  Exercise: "exercise",
  Test: "test",
}

export const DialogStatus = {
  Default: "default",
  Loading: "loading",
  Success: "Success",
  Error: "Error"
}

export const TestType = {
  SingleChoice: "single_choice",
  MultipleChoice: "multiple_choice",
  TrueFalse: "true_false",
  Matching: "matching",
  TextCompletion: "text_completion"
}

export const CorrectionType = {
  NumberOfErrors: "number_of_errors",
  Errors: "errors",
  Solutions: "solutions"
}

export const TestStatus = {
  Draft: "draft",
  Public: "public",
  Completed: "completed"
}

export const TestResultStatus = {
  Passed: "passed",
  Failed: "failed",
  NotCompleted: "notCompleted"
}


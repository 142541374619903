import { useEffect, useState } from "react"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { Each } from "../common/Each"
import styles from "./CollapsableStudents.module.css"
import Button from "./Button"

const CollapsableStudents = ({ title = "", students = [], expanded = false, onExpand, badgeColor = '#B5B8BE', action }) => {

    const [exp, setExp] = useState(expanded)

    useEffect(() => {
        setExp(expanded ?? false)
    }, [expanded])

    const toggleExpand = () => {
        if (!exp && onExpand && typeof onExpand === 'function') {
            onExpand()
        }
        setExp(!exp)
    }

    return (
        <div className={styles.container}>
            <div className={`${styles.header} ${exp ? styles.expanded : ''}`} onClick={toggleExpand}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
                    <div className={styles.badge} style={{ backgroundColor: badgeColor }}></div>
                    {title} • {students.length}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
                    {action &&
                        <Button accentColor="#FDB323" 
                        style={{padding: '.5rem 1rem'}}
                        onClick={(event) => {
                            // event.stopPropagation()
                            action.onClick()
                        }}>
                            {action.icon}
                            {action.label}
                        </Button>
                    }
                    <ChevronIcon className={`${styles.chevronIcon} ${exp ? styles.expanded : ''}`}></ChevronIcon>
                </div>
            </div>
            {exp &&
                <div className={styles.divider}></div>
            }
            <div className={`${styles.content} ${(exp && students.length > 0) ? styles.expanded : ''}`}>
                <Each of={students} render={(student) => {
                    return (
                        <div className={styles.student}>
                            <img src={student.picture} alt="" />
                            <div className={styles.studentName}>
                                {student.name} {student.surname}
                            </div>
                        </div>
                    )
                }} />
            </div>
        </div>
    )
}

export default CollapsableStudents
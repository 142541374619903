
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import { ReactComponent as ExerciseIcon } from "../../assets/images/icons/ic-exercise.svg"
import { Each } from "../../common/Each"
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-edit.svg"
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/ic-delete.svg"
import { FeedType } from "../../common/constants"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import { calcLastUpdate, capitalize, formatDateV2, formatTimeV2 } from "../../utils"
import MaterialButton from "../MaterialButton"
import Card from "./Card"
import styles from "./ExerciseFeedCard.module.css"
import MeatBallsMenu from "../MeatBallsMenu"
import { DialogStatus } from "../../enums"
import ExerciseDialog from "../dialogs/ExerciseDialog"
import api from "../../api"
import AlertDialog from "../dialogs/AlertDialog"

const ExerciseFeedCard = ({ activity = {}, onEdit = () => { }, onDelete = () => { } }) => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  // delete
  const [openAlert, setOpenAlert] = useState(false)
  const [alertStatus, setAlertStatus] = useState(DialogStatus.Default)
  const [closeTimeout, setCloseTimeout] = useState(null)

  // edit 
  const [open, setOpen] = useState(false)
  const [dialogStatus, setDialogStatus] = useState(DialogStatus.Default)
  const [dialogTimeout, setDialogTimeout] = useState(null)

  const exerciseMedias = useMemo(() => {
    if (!activity || activity.type !== FeedType.Exercise) {
      return []
    }
    return activity.object?.medias ? activity.object.medias : []
  }, [activity])

  const menuActions = useMemo(() => {
    if (!activity) return []

    const actions = [
      { label: t("edit"), icon: EditIcon, style: { color: "var(--primary)" }, onClick: () => setOpen(true) },
      { label: t("delete"), icon: DeleteIcon, style: { color: "var(--secondary)" }, onClick: () => setOpenAlert(true) }
    ]

    return actions
  }, [activity])

  const onSave = useCallback(async () => {
    onEdit({ activity_id: activity.id })
    setDialogTimeout(setTimeout(() => setOpen(false), 4000))
  }, [])

  const deleteExercise = useCallback(async () => {
    try {
      setAlertStatus(DialogStatus.Loading)
      await api.delete(`/teacher/exercises/${activity.object_id}`)
      onDelete(activity.id)
      setAlertStatus(DialogStatus.Success)
      setCloseTimeout(setTimeout(() => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
      }, 3000))
    } catch (e) {
      console.error(e)
      setAlertStatus(DialogStatus.Error)

      setCloseTimeout(setTimeout(() => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
      }, 3000))
    }
  }, [activity])


  return (
    <Card style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
      <div className={styles.cardHeader}>
        <div className={`${styles.icon} ${styles.exercise}`}>
          <ExerciseIcon />
        </div>
        <div className={styles.info}>
          <div className={styles.title}>
            {activity.object.name}
          </div>
          <div className={styles.subtitle}>
            {
              activity.object.expires_at &&
              <>
                {width > 540 &&
                  <>
                    Entro le {formatTimeV2(activity.object.expires_at)} di {capitalize(formatDateV2(activity.object.expires_at, { weekday: 'long', day: 'numeric', month: 'long' }))}
                  </>
                }
                {width < 540 &&
                  <>
                    Entro {formatDateV2(activity.object.expires_at, { weekday: 'short', day: 'numeric', month: 'short' }).toUpperCase()}, {formatTimeV2(activity.object.expires_at)}
                  </>
                }
              </>
            }
          </div>
        </div>
        <div className={styles.date}>
          {calcLastUpdate(activity.created_at, width > 768 ? false : true)}
          <ClockIcon className={styles.clockIcon} />
        </div>
        <MeatBallsMenu actions={menuActions} />

      </div>
      <div className={styles.content}>
        <div className={styles.description} style={{ padding: 0 }}>
          {activity.object.description}
        </div>
        {
          exerciseMedias.length > 0 &&
          <div className={styles.filesContainer}>
            <div className={styles.files}>
              <Each
                of={exerciseMedias}
                render={(file) => (
                  <div className={styles.file}>
                    <MaterialButton
                      material={file}
                      downloadable
                    />
                  </div>
                )}
              />
            </div>
          </div>
        }

      </div>
      <AlertDialog
        open={openAlert}
        title={t("exercises.deleteTitle")}
        text={t("exercises.deleteText")}
        status={alertStatus}
        onClose={() => {
          setOpenAlert(false)
          setAlertStatus(DialogStatus.Default)
          if (closeTimeout) {
            clearTimeout(closeTimeout)
          }
        }}
        actions={[
          {
            label: t("exercises.deleteConfirm").toUpperCase(),
            onClick: deleteExercise,
          }
        ]}
      />

      <ExerciseDialog
        open={open}
        onClose={() => {
          if (dialogTimeout) {
            clearTimeout(dialogTimeout)
            setDialogTimeout(null)
          }
          setOpen(false)
        }}
        exercise={activity.object}
        status={dialogStatus}
        onSave={onSave}
      />
    </Card >
  )
}

export default ExerciseFeedCard

import { Navigate } from 'react-router-dom';
import { auth } from "../firebase"
import { useEffect, useState } from 'react';
import Loader from './Loader';
import typo from "../typography.module.css"

const ProtectedRoute = ({ component }) => {

    const [authUser, setAuthUser] = useState(undefined)

    useEffect(() => {
        auth.authStateReady().then(() => {
            setAuthUser(auth.currentUser)
        })
    }, [])

    return (
        <>
            {authUser === null &&
                <Navigate to="/login" />
            }
            {!authUser?.isAnonymous &&
                component
            }
            {authUser === undefined &&
                <div className="loader-container">
                    <Loader />
                    <div className={typo.headline}>
                        Caricamento...
                    </div>
                </div>
            }
        </>
    );
}

export default ProtectedRoute
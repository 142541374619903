import {
  BoldItalicUnderlineToggles,
  diffSourcePlugin,
  MDXEditor,
  toolbarPlugin
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/ic-delete.svg"
import Card from "../cards/Card"
import TrueFalseBadge from "./TrueFalseBadge"
import styles from "./TrueFalseQuestion.module.css"

const TrueFalseQuestion = ({ id, label, onDelete = () => { }, onChange = () => { }, text = "", value = null }) => {

  const [_text, setText] = useState(text)
  const [_value, setValue] = useState(value)
  const { t } = useTranslation()

  useEffect(() => {
    onChange({ id, text: _text, value: _value })
  }, [_text, _value])

  useEffect(() => {
    if (text !== _text) {
      setText(text)
    }

    if (value !== _value) {
      setValue(value)
    }
  }, [text, value])

  return (
    <Card hover style={{ padding: ".5rem 1rem", width: "100%" }}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.label}>{label}</div>
          <div className="_mdxeditor-inline">
            <MDXEditor
              className={styles.editor}
              contentEditableClassName={styles.editorContent}
              markdown={_text}
              onChange={(value) => {
                setText(value)
              }}
              placeholder={t("tests.answerPlaceholderTrueFalse")}
              plugins={[
                diffSourcePlugin(),
                toolbarPlugin({
                  toolbarContents: () => (
                    <BoldItalicUnderlineToggles options={["Bold", "Italic"]} />
                  )
                })
              ]}
            />
          </div>
        </div>
        <div className={styles.actions}>
          <TrueFalseBadge type={true} value={_value} onChange={(value) => setValue(value)} />
          <TrueFalseBadge type={false} value={_value} onChange={(value) => setValue(value)} />
          <DeleteIcon className={styles.delete} onClick={onDelete} />
        </div>
      </div>
    </Card>
  )
}

export default TrueFalseQuestion

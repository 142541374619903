import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import api from "../../api"
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/ic-calendar.svg"
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/ic-delete.svg"
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-edit.svg"
import { ReactComponent as ExerciseIcon } from "../../assets/images/icons/ic-exercise.svg"
import { ReactComponent as VariantExerciseIcon } from "../../assets/images/icons/ic-exercise-card.svg"
import { ReactComponent as MaterialIcon } from "../../assets/images/icons/ic-file.svg"
import { ReactComponent as LessonIcon } from "../../assets/images/icons/ic-lessons-inactive.svg"
import { ReactComponent as StudentsIcon } from "../../assets/images/icons/ic-students.svg"
import { DialogStatus } from "../../enums"
import { formatDateV2, formatTimeV2 } from "../../utils"
import AlertDialog from "../dialogs/AlertDialog"
import MeatBallsMenu from "../MeatBallsMenu"
import Skeleton from "../Skeleton"
import styles from "./ExerciseCard.module.css"

const ExerciseCard = ({ exercise, onEdit = () => { }, onDelete = () => { }, loading = false, useVariant = false }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [openAlert, setOpenAlert] = useState(false)
  const [alertStatus, setAlertStatus] = useState(DialogStatus.Default)
  const [closeTimeout, setCloseTimeout] = useState(null)

  const deleteExercise = useCallback(async () => {
    try {
      setAlertStatus(DialogStatus.Loading)
      await api.delete(`/teacher/exercises/${exercise.id}`)
      onDelete(exercise.id)
      setAlertStatus(DialogStatus.Success)
      setCloseTimeout(setTimeout(() => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
      }, 3000))
    } catch (e) {
      console.error(e)
      setAlertStatus(DialogStatus.Error)

      setCloseTimeout(setTimeout(() => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
      }, 3000))
    }
  }, [exercise])

  const menuActions = useMemo(() => {
    if (!exercise) return []

    const actions = [
      { label: t("edit"), icon: EditIcon, style: { color: "var(--primary)" }, onClick: onEdit },
      { label: t("delete"), icon: DeleteIcon, style: { color: "var(--secondary)" }, onClick: () => setOpenAlert(true) }
    ]

    return actions
  }, [exercise])

  const materialsNumber = useMemo(() => {
    if (!exercise) return []
    const { medias } = exercise

    return medias?.length ?? 0
  }, [exercise])

  return (
    <div className={styles.container}>
      {
        loading === true &&
        <>
          <div className={styles.header}>
            <Skeleton type="rect" width="144px" height="16px" borderRadius="4px" />
            <MeatBallsMenu disabled />
          </div>
          <div className={styles.content}>
            <Skeleton type="rect" width="196px" height="16px" borderRadius="4px" />
            <div className={styles.labeled}>
              <Skeleton type="rect" width="256px" height="16px" borderRadius="4px" />
              <Skeleton type="rect" width="144px" height="16px" borderRadius="4px" />
            </div>
            <div className={styles.labeled}>
              <Skeleton type="rect" width="196px" height="16px" borderRadius="4px" />
              <Skeleton type="rect" width="96px" height="16px" borderRadius="4px" />
            </div>
            <div className={styles.row}>
              <div className={styles.labeled}>
                <Skeleton type="rect" width="164px" height="16px" borderRadius="4px" />
                <Skeleton type="rect" width="88px" height="16px" borderRadius="4px" />
              </div>
              <div className={styles.labeled}>
                <Skeleton type="rect" width="96px" height="16px" borderRadius="4px" />
                <Skeleton type="rect" width="196px" height="16px" borderRadius="4px" />
              </div>
            </div>
          </div>
        </>
      }

      {
        !loading &&
        <>
          <div className={styles.header}>
            <div className={styles.leftHeader}>
              <div className={styles.name} onClick={() => navigate(`/exercises/${exercise.id}`)}>
                {!useVariant && <ExerciseIcon style={{ color: "var(--primary)", marginRight: ".2rem" }} />}
                {useVariant && <VariantExerciseIcon style={{ marginRight: ".2rem" }} />}
                {exercise.name ?? ""}
              </div>
            </div>
            {!exercise.deleted_at && <MeatBallsMenu actions={menuActions} />}
          </div>
          <div className={styles.content}>
            <div className={styles.description}>{exercise.description ?? ""}</div>
            <div className={styles.labeled}>
              <div className={styles.label}>{t("exercises.lesson.label")}</div>
              <div className={styles.value}><LessonIcon /> {exercise.lesson?.name ?? t("exercises.lesson.empty")}</div>
            </div>
            {
              exercise.expires_at &&
              <div className={styles.labeled}>
                <div className={styles.label}>{t("exercises.expiresAt")}</div>
                <div className={styles.value}><CalendarIcon /> {formatDateV2(exercise.expires_at)} {formatTimeV2(exercise.expires_at)} </div>
              </div>
            }
            <div className={styles.row} style={{
              width: useVariant ? "60%" : "100%",
              justifyContent: useVariant ? "space-between" : "flex-start",
            }}>
              <div className={styles.labeled}>
                <div className={styles.label}>{t("exercises.materials")}</div>
                <div className={styles.value}><MaterialIcon />{materialsNumber}</div>
              </div>
              <div className={styles.labeled}>
                <div className={styles.label}>{t("exercises.submitNumber")}</div>
                <div className={styles.value}><StudentsIcon />{exercise.users_exercises_count} {t("outOf")} {exercise.users}</div>
              </div>
            </div>
          </div>

          <AlertDialog
            open={openAlert}
            title={t("exercises.deleteTitle")}
            text={t("exercises.deleteText")}
            status={alertStatus}
            onClose={() => {
              setOpenAlert(false)
              setAlertStatus(DialogStatus.Default)
              if (closeTimeout) {
                clearTimeout(closeTimeout)
              }
            }}
            actions={[
              {
                label: t("exercises.deleteConfirm").toUpperCase(),
                onClick: deleteExercise,
              }
            ]}
          />
        </>
      }
    </div>
  )
}

export default ExerciseCard

import styles from "./LiveBadge.module.css"

const LiveBadge = ({style}) => {

    return (
        <div className={styles.container} style={style}>
            <div className={styles.onair}></div>
            LIVE
        </div>
    )
}

export default LiveBadge
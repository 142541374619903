import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactSortable } from "react-sortablejs"
import { v4 } from "uuid"
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg"
import { isTestTrueFalseValid, toLetter } from "../../utils"
import Answer from "./Answer"
import DropdownMultiSelection from "../DropdownMultiSelection"
import SortableContainer from "../SortableContainer"
import Tag from "../Tag"
import Card from "../cards/Card"
import styles from "./TestTrueFalse.module.css"
import TrueFalseQuestion from "./TrueFalseQuestion"
import TagSelector from "../TagSelector"

const TestTrueFalse = ({
  tags = [],
  answers = [{
    id: v4(),
    text: "",
    value: null
  }, {
    id: v4(),
    text: "",
    value: null
  }],
  tagList = [],
  onChange = () => { },
}) => {
  const { t } = useTranslation()

  const [_answers, setAnswers] = useState(answers)
  const [filteredTagsList, setFilteredTagList] = useState(tagList)
  const [_tags, setTags] = useState(tags)

  useEffect(() => {
    setFilteredTagList(tagList)
  }, [tagList])

  useEffect(() => {
    const ids = _tags.map(t => t.id)
    if (ids.length > 0) {
      setFilteredTagList(tagList.filter(t => ids.includes(t.id) === false))
    } else {
      setFilteredTagList(tagList)
    }
  }, [_tags])

  const addAnswer = useCallback(() => {
    if (_answers.length >= 10) {
      return;
    }
    _answers.push({
      id: v4(),
      text: "",
      value: null
    })
    setAnswers([..._answers])
  }, [_answers])

  const removeAnswer = useCallback((removedIndex) => {
    _answers.splice(removedIndex, 1);
    setAnswers([..._answers])
  }, [_answers])

  const changeAnswer = useCallback((answer) => {
    const index = _answers.findIndex(a => a.id === answer.id)
    if (index >= 0) {
      _answers[index] = answer
      setAnswers([..._answers])
    }
  }, [_answers])

  useEffect(() => {
    const validation = isTestTrueFalseValid(_answers)
    const { isValid, everyAnswerHasSolution, isAnswerTextValid } = validation
    let validationError = ""
    if (!everyAnswerHasSolution && !isAnswerTextValid) {
      validationError = ""
    } else if (!everyAnswerHasSolution) {
      validationError = t("tests.validation.noTrueFalseSolution")
    } else if (!isAnswerTextValid) {
      validationError = t("tests.validation.noTextAnswersTrueFalse")
    }

    const changes = {
      answers: _answers,
      tags: _tags,
      isValid,
      validationError,
    }
    onChange(changes)
  }, [_answers, _tags])

  return (
    <div className={styles.container}>
      <TagSelector
        selected={_tags}
        options={filteredTagsList}
        placeholder={(_tags.length === 0 && t("tests.tagsPlaceholder")) || ""}
        onAdd={(item) => {
          console.debug(item)
          setTags(t => {
            t.push(item)
            return [...t]
          })
        }}
        onRemove={(item) => {
          setTags(t => {
            t = t.filter((tt) => tt.id !== item.id)
            return [...t]
          })
        }}
      />
      <ReactSortable swap tag={SortableContainer} list={_answers} setList={setAnswers}>
        {
          _answers.map(
            (answer, index) => (
              <TrueFalseQuestion
                key={answer.id}
                id={answer.id}
                text={answer.text}
                value={answer.value}
                label={answer.label || toLetter(index + 1).toUpperCase()}
                onDelete={() => { removeAnswer(index) }}
                onChange={(newAnswer) => { changeAnswer(newAnswer) }}
              />
            )
          )
        }

      </ReactSortable>
      <div className={styles.addAnswer} onClick={addAnswer}>+ {t("tests.addAnswer")}</div>

    </div >
  )
}

export default TestTrueFalse

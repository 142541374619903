
export const CourseType = {
    Masterclass: "masterclass",
    Professional: "professional"
}

export const CourseStatus = {
    ToStart: "to_start",
    InProgress: "in_progress",
    Ended: "ended"
}

export const LessonStatus = {
    ToStart: "to_start",
    Live: "live",
    Ended: "ended",
    All: ["live", "to_start", "ended"]
}

export const TagType = {
    General: "general",
    Feature: "feature"
}

export const ArticleCardType = {
    Large: "large",
    Medium: "medium",
    Small: "small"
}

export const FeedType = {
    Lesson: "lesson",
    Exercise: "exercise",
    Material: "material",
    Communication: "communication",
    Test: "test"
}

export const palette = ['#1F94FF', '#FFD600', '#F14FFF', '#FF9A8C', '#2fc6a0', '#B966FA']
export const darkPalette = ['#99275E', '#0A4081', '#994BC9CC', '#4BC9A3CC', '#4B8CC9CC']

export const Stripe = {
    publicKey: 'pk_test_51OkqgmHGtSMxXXMSjWJSEeMgIsM2adVltIURTRJcNYUvJfGKr262V0qCTx1aMu29EMDQ7qkHdXVkwW1Y2Vv5Jy8b00q4ZoPPYN'
}

export const PaymentStatus = {
    Succeeded: 'succeeded',
    Failed: 'failed',
    Pending: 'pending',
    Refunded: 'refunded',
    Processing: 'processing'
}

export const NotificationType = {
    LessonLive: 'lesson_live',
    LessonEdit: 'lesson_edit',
    ExerciseReminder: 'exercise_reminder',
    ExerciseResult: 'exercise_result',
    ExerciseExpired: 'exercise_expired',
    MaterialUpload: 'material_upload',
    CourseCompleted: 'course_completed',
    Announcement: 'announcement'
}

export const ButtonStatus = {
    Loading: "loading",
    Success: "success",
    Error: "error"
}

export const ProfileType = {
    User: "user",
    Teacher: "teacher",
    Admin: "admin"
}
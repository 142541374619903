import styles from "./Dialog.module.css"
import typo from "../../typography.module.css"
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg"
import { DialogStatus } from "../../enums"
import Loader from "../Loader"
import { ReactComponent as ErrorIcon } from "../../assets/images/icons/ic-error.svg"
import Succeeded from "../animated/Succeeded"
import { useTranslation } from "react-i18next"

const Dialog = ({ open, title, children, onClose, style, action, noHeader = false, status = null, errorMessage = "", successMessage = "", contentMaxHeight = "60vh" }) => {
    const { t } = useTranslation()

    const handleClose = () => {
        if (onClose && typeof onClose === 'function') {
            onClose()
        }
    }


    return (
        <>
            {open &&
                <div className={styles.container}>
                    <div className={styles.card} style={style}>
                        <div className={`${noHeader ? `${styles.header} ${styles.noHeaderStyle}` : styles.header}`}>
                            <div className={[typo.subtitle, styles.title].join(' ')}>{title}</div>
                            <button className={styles.closeButton} onClick={handleClose}>
                                <CloseIcon></CloseIcon>
                            </button>
                        </div>
                        <div className={styles.content} style={{ maxHeight: contentMaxHeight }}>
                            {
                                (status === null || status === DialogStatus.Default) && children
                            }

                            {status === DialogStatus.Success &&
                                <div className={styles.resultContainer}>
                                    <Succeeded width="60%" height="60%" />
                                    {
                                        successMessage &&
                                        <div className={styles.title}>{successMessage}</div>
                                    }
                                </div>
                            }
                            {status === DialogStatus.Error &&
                                <div className={styles.error}>
                                    <ErrorIcon className={styles.icon} style={{ color: "var(--secondary)" }} />
                                    <div className={styles.errorMessage}>{errorMessage || t("dialog.defaultError")}</div>
                                </div>
                            }
                            {
                                status === DialogStatus.Loading &&
                                <div className={styles.resultContainer}> <Loader /> </div>
                            }
                        </div>
                        {action &&
                            <div className={styles.action}>
                                {action}
                            </div>
                        }
                    </div>
                </div>
            }
        </>

    )
}

export default Dialog
